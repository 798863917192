import axios from "axios";

export const getOrganizationList = async () => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const bearerToken = `Bearer ${accessToken}`;

    const apiDomain: string =
      window.location.origin !== "http://localhost:3001"
        ? process.env.REACT_APP_API_DOMAIN || "http://localhost:3000"
        : "http://localhost:3000";

    const email = localStorage.getItem("email");
    const url = `${apiDomain}/api/v2/auth/passwordless/${email}/workspaces`
    const response = await axios.get(url, { headers: { 'Authorization': bearerToken } });
    return response.data;
  } catch (err) {
    console.log("🚀 ~ Error while getting organizations list", err);
    return [];
  }
};
