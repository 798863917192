import axios from "axios";
import {
  INVITATION_NOT_FOUND,
  INVITATION_EXPIRED_OR_REVOKED,
  INVITATION_ACCEPTED_OR_INVALID_USER,
  INVITATION_ACCEPTED_OR_VALID_USER,
  INVITATION_PENDING_AND_VALID,
  INTERNAL_SERVER_ERROR
} from "../Constants/MessageConstants";

const subdomain = window.location.host.split(".")[0];
const apiDomain: string =
  window.location.origin !== `http://${subdomain}.localhost:3000`
    ? process.env.REACT_APP_API_DOMAIN || "http://localhost:3000"
    : "http://localhost:3000";


export async function ValidateInvitation(
  workspaceId: string,
  invitationId: string,
  invitationToken: string
): Promise<any> {
  try {
    const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/invitations/${invitationId}/validate`
    const response = await axios.get(url)

    const responseData = response.data;

    switch (responseData.code) {
      case "InvitationAlreadyAcceptedValidUser":
        return INVITATION_ACCEPTED_OR_VALID_USER;
      case "InvitationPendingAndValid":
        return INVITATION_PENDING_AND_VALID;
      case "InvitationNotFound":
        return INVITATION_NOT_FOUND;
      case "InvitationExpiredOrRevoked":
        return INVITATION_EXPIRED_OR_REVOKED;
      case "InvitationAlreadyAcceptedInvalidUser":
        return INVITATION_ACCEPTED_OR_INVALID_USER;
      default:
        return INTERNAL_SERVER_ERROR;
    }
  } catch (error) {
    console.log('SERVER ERROR :', error)
    return 'Server Error. Please contact admin'
  }
}
