'use client';

import CircleLogoTop from './images/Circle_Logo.svg';

interface ICircleLogo {
    height: number;
    width: number;
}

export default function CircleLogo(props: ICircleLogo) {
    const logoHeight = props.height || 100;
    const logoWidth = props.width || 100;

    return (
        <>
            <a href={`/`}>
                <img className="p-2" src={CircleLogoTop} alt='CircleLogo' width={logoWidth} height={logoHeight} />
            </a>
        </>
    );

}