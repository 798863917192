import { FC } from "react";
import { INFO_SCREEN, SELFIE_RULE_RESULT } from "./constants";
import leftArrowIcon from "./images/arrow-left.svg";

interface HeaderProps {
  clickedSelfie: string;
  onShowInfoScreen: (screen: INFO_SCREEN) => void;
  selfieResult: SELFIE_RULE_RESULT | undefined;
  onRetake: () => void;
}

const Header: FC<HeaderProps> = ({
  clickedSelfie,
  onShowInfoScreen,
  selfieResult,
  onRetake,
}) => {
  return (
    <div
      className={`flex justify-between border-b border-gray-200 pb-2 ${
        selfieResult ? "px-12" : "px-6"
      }`}
    >
      {selfieResult ? (
        <div className="cursor-pointer role='button" onClick={onRetake}>
          <img src={leftArrowIcon} alt="left arrow" />
        </div>
      ) : (
        <div />
      )}
      <div className="text-sm font-medium text-gray-900">Capture Photo</div>
      {!clickedSelfie ? (
        <div
          className="text-[12px] font-medium text-gray-600 cursor-pointer"
          role="button"
          onClick={() => onShowInfoScreen(INFO_SCREEN.CANCEL)}
        >
          Cancel
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default Header;
