"use client";
import { useEffect, useRef, useState } from "react";
import CircleLogo from "./CircleLogo";
import { Button } from "flowbite-react";
import ArrowLeft from "./images/arrow-left.svg";
import "./login.css";
import { verifyOTP } from "../../../Utilities/verifyOTPMethod";
import { WRONG_OTP_ERROR_MESSAGE } from "../../../Constants/MessageConstants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sendOTP } from "../../../Utilities/sendOTPMethod";
import {
  CORRECT_TEXTBOX,
  NORMAL_TEXTBOX,
  WRONG_TEXTBOX,
} from "../../../Constants/StyleConstants";
import LoadingSpinner from "../UserPortal/LoadingSpinner";

interface ILoginSignUpEmailOTPScreen {
  setSignUpStep(val: number): void;
  setUserEmail(email: string): void;
  userEmail: string;
  isExistingUser: boolean;
}

export default function LoginSignUpEmailOTPScreen(
  props: ILoginSignUpEmailOTPScreen
) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isWrongOTP, setIsWrongOTP] = useState<boolean>(false);
  const [isRightOTP, setIsRightOTP] = useState<boolean>(false);
  const [isCodeExpired, setIsCodeExpired] = useState<boolean>(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState<string | null>("");
  const [firstOTPDigit, setFirstOTPDigit] = useState<Number | null>(null);
  const [secondOTPDigit, setSecondOTPDigit] = useState<Number | null>(null);
  const [thirdOTPDigit, setThirdOTPDigit] = useState<Number | null>(null);
  const [fourthOTPDigit, setFourthOTPDigit] = useState<Number | null>(null);
  const [fifthOTPDigit, setFifthOTPDigit] = useState<Number | null>(null);
  const [sixthOTPDigit, setSixthOTPDigit] = useState<Number | null>(null);
  const firstOTPInput = useRef<any>(null);
  const secondOTPInput = useRef<any>(null);
  const thirdOTPInput = useRef<any>(null);
  const fourthOTPInput = useRef<any>(null);
  const fifthOTPInput = useRef<any>(null);
  const sixthOTPInput = useRef<any>(null);
  const [seconds, setSeconds] = useState(30);
  const [searchParams] = useSearchParams();
  const [loadingText, setLoadingText] = useState<string>("");
  const email = searchParams.get("email");
  useEffect(() => {
    // if (firstOTPInput && firstOTPInput.current)
    //     firstOTPInput.current.focus();
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [seconds]);

  const clearOTP = () => {
    setSeconds(30);
    if (seconds > 0) {
      const timer = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
    setFirstOTPDigit(null);
    setSecondOTPDigit(null);
    setThirdOTPDigit(null);
    setFourthOTPDigit(null);
    setFifthOTPDigit(null);
    setSixthOTPDigit(null);
    setIsWrongOTP(false);
    setIsRightOTP(false);
    setOtpErrorMessage("");
    sendEmailOTP();
    setIsCodeExpired(false);
  };

  const sendEmailOTP = async () => {
    await sendOTP(props.userEmail);
  };

  const buildOTP = (index: number, value: number | null): void => {
    setIsWrongOTP(false);
    setIsRightOTP(false);
    setOtpErrorMessage("");
    let currentOTP: string = "";
    switch (index) {
      case 1:
        currentOTP = `${value}${secondOTPDigit}${thirdOTPDigit}${fourthOTPDigit}${fifthOTPDigit}${sixthOTPDigit}`;
        break;
      case 2:
        currentOTP = `${firstOTPDigit}${value}${thirdOTPDigit}${fourthOTPDigit}${fifthOTPDigit}${sixthOTPDigit}`;
        break;
      case 3:
        currentOTP = `${firstOTPDigit}${secondOTPDigit}${value}${fourthOTPDigit}${fifthOTPDigit}${sixthOTPDigit}`;
        break;
      case 4:
        currentOTP = `${firstOTPDigit}${secondOTPDigit}${thirdOTPDigit}${value}${fifthOTPDigit}${sixthOTPDigit}`;
        break;
      case 5:
        currentOTP = `${firstOTPDigit}${secondOTPDigit}${thirdOTPDigit}${fourthOTPDigit}${value}${sixthOTPDigit}`;
        break;
      case 6:
        currentOTP = `${firstOTPDigit}${secondOTPDigit}${thirdOTPDigit}${fourthOTPDigit}${fifthOTPDigit}${value}`;
        break;
      default:
        break;
    }
    if (currentOTP.length === 6) {
      verifyEmailOTP(`${currentOTP}`);
    }
  };

  const verifyEmailOTP = async (otp: string) => {
    // const verificationCodeRequestId = localStorage.getItem(
    //   "verificationCodeRequestId"
    // );
    const response: any = await verifyOTP(
      otp,
      props.userEmail,
      // verificationCodeRequestId as string
    );
    if (response.status !== 200) {
      setIsWrongOTP(true);
      setIsRightOTP(false);
      setOtpErrorMessage(WRONG_OTP_ERROR_MESSAGE);
    } else {
      setIsRightOTP(true);
      setIsWrongOTP(false);
      setOtpErrorMessage("");
      navigate("/selectOrganization");
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner text={loadingText} />
      ) : (
        <div className="w-[25rem] h-auto bg-white rounded-xl shadow-lg border border-gray-300 flex flex-col justify-between items-center gap-1 p-5">
          <div className="flex justify-start w-full">
            {!email && (
              <Button
                onClick={() => {
                  props.setSignUpStep(1);
                  props.setUserEmail("");
                }}
                size="sm"
                className="flex justify-center space-x-3 items-center bg-white border border-gray-200 enabled:hover:bg-gray-200 ring-0 focus:ring-0 focus:border-primary-500 transition-colors ease-in-out duration-150"
              >
                <img src={ArrowLeft} alt="leftArrowImage" className="px-0.5" />
                <div className="text-md text-black">Back</div>
              </Button>
            )}
          </div>
          <CircleLogo height={100} width={100} />
          <div className="flex-col justify-center items-center gap-4 inline-flex">
            <div className="w-80 text-center text-gray-600 text-xs font-normal">
              <div className="text-2xl font-medium py-2">Check your inbox</div>
              <span>We’ve sent a 6 digit code to</span>
              <span className=" font-bold text-sm text-primary-500">
                {" "}
                {props.userEmail}
              </span>
              <span>
                <br />
                The code expires shortly, so please enter it soon.
              </span>
            </div>
            {!email && (
              <Button
                onClick={() => {
                  props.setSignUpStep(1);
                  props.setUserEmail("");
                }}
                size="xs"
                className="flex justify-center space-x-3 items-center bg-white border border-gray-200 enabled:hover:bg-gray-200 ring-0 focus:ring-0 focus:border-primary-500 transition-colors ease-in-out duration-150"
              >
                <img src={ArrowLeft} alt="leftArrowImage" className="px-0.5" />
                <div className="text-md text-black">Change Email</div>
              </Button>
            )}
            {!isCodeExpired ? (
              <div className="flex gap-2 justify-center items-center my-2">
                <input
                  autoFocus
                  disabled={isRightOTP}
                  className={[
                    "w-10 h-10 text-center px-3 py-2 rounded-lg border justify-center items-center gap-2 inline-flex",
                    !isWrongOTP
                      ? isRightOTP
                        ? CORRECT_TEXTBOX
                        : NORMAL_TEXTBOX
                      : WRONG_TEXTBOX,
                  ].join(" ")}
                  ref={firstOTPInput}
                  value={
                    firstOTPDigit || firstOTPDigit === 0
                      ? firstOTPDigit.toString()
                      : ""
                  }
                  onPaste={(e: any) => {
                    const text: string = (
                      e.originalEvent || e
                    ).clipboardData.getData("text/plain");
                    if (text.match(/^\d{6}$/)) {
                      setFirstOTPDigit(parseInt(text[0]));
                      setSecondOTPDigit(parseInt(text[1]));
                      setThirdOTPDigit(parseInt(text[2]));
                      setFourthOTPDigit(parseInt(text[3]));
                      setFifthOTPDigit(parseInt(text[4]));
                      setSixthOTPDigit(parseInt(text[5]));
                      verifyEmailOTP(text);
                      sixthOTPInput.current.focus();
                    }
                  }}
                  onKeyDown={(event: any) => {
                    if (event.key === "Backspace") {
                      if (firstOTPDigit !== null) {
                        setFirstOTPDigit(null);
                        buildOTP(1, null);
                      } else {
                        firstOTPInput.current.focus();
                      }
                    }
                    if (!isNaN(parseInt(event.key))) {
                      if (firstOTPDigit === null) {
                        setFirstOTPDigit(parseInt(event.key));
                        buildOTP(1, parseInt(event.key));
                      } else {
                        if (secondOTPInput && secondOTPInput.current) {
                          setSecondOTPDigit(event.key);
                        }
                      }
                      secondOTPInput.current.focus();
                    }
                    if (event.key === "ArrowRight") {
                      secondOTPInput.current.focus();
                    }
                  }}
                  minLength={0}
                  maxLength={1}
                  type="text"
                  id="firstOTPDigit"
                />

                <input
                  ref={secondOTPInput}
                  disabled={isRightOTP}
                  className={[
                    "w-10 h-10 text-center px-3 py-2 rounded-lg border justify-center items-center gap-2 inline-flex",
                    !isWrongOTP
                      ? isRightOTP
                        ? CORRECT_TEXTBOX
                        : NORMAL_TEXTBOX
                      : WRONG_TEXTBOX,
                  ].join(" ")}
                  value={
                    secondOTPDigit || secondOTPDigit === 0
                      ? secondOTPDigit.toString()
                      : ""
                  }
                  onKeyDown={(event: any) => {
                    if (event.key === "ArrowRight") {
                      thirdOTPInput.current.focus();
                    }

                    if (event.key === "ArrowLeft") {
                      firstOTPInput.current.focus();
                    }

                    if (event.key === "Backspace") {
                      if (secondOTPDigit !== null) {
                        setSecondOTPDigit(null);
                        buildOTP(2, null);
                      } else {
                        firstOTPInput.current.focus();
                        setFirstOTPDigit(null);
                      }
                    }
                    if (!isNaN(parseInt(event.key))) {
                      if (secondOTPDigit == null) {
                        setSecondOTPDigit(event.key);
                        buildOTP(2, parseInt(event.key));
                      } else {
                        if (thirdOTPInput && thirdOTPInput.current) {
                          setThirdOTPDigit(event.key);
                        }
                      }
                      thirdOTPInput.current.focus();
                    }
                  }}
                  minLength={0}
                  maxLength={1}
                  type="text"
                  id="secondOTPDigit"
                />
                <input
                  ref={thirdOTPInput}
                  disabled={isRightOTP}
                  className={[
                    "w-10 h-10 text-center px-3 py-2 rounded-lg border justify-center items-center gap-2 inline-flex",
                    !isWrongOTP
                      ? isRightOTP
                        ? CORRECT_TEXTBOX
                        : NORMAL_TEXTBOX
                      : WRONG_TEXTBOX,
                  ].join(" ")}
                  value={
                    thirdOTPDigit || thirdOTPDigit === 0
                      ? thirdOTPDigit.toString()
                      : ""
                  }
                  onKeyDown={(event: any) => {
                    if (event.key === "ArrowRight") {
                      fourthOTPInput.current.focus();
                    }

                    if (event.key === "ArrowLeft") {
                      secondOTPInput.current.focus();
                    }
                    if (event.key === "Backspace") {
                      if (thirdOTPDigit !== null) {
                        setThirdOTPDigit(null);
                        buildOTP(3, null);
                      } else {
                        secondOTPInput.current.focus();
                        setSecondOTPDigit(null);
                      }
                    }
                    if (!isNaN(parseInt(event.key))) {
                      if (thirdOTPDigit == null) {
                        setThirdOTPDigit(event.key);
                        buildOTP(3, parseInt(event.key));
                      } else {
                        if (fourthOTPInput && fourthOTPInput.current) {
                          setFourthOTPDigit(event.key);
                        }
                      }
                      fourthOTPInput.current.focus();
                    }
                  }}
                  minLength={0}
                  maxLength={1}
                  type="text"
                  id="thirdOTPDigit"
                />

                <input
                  ref={fourthOTPInput}
                  disabled={isRightOTP}
                  className={[
                    "w-10 h-10 text-center px-3 py-2 rounded-lg border justify-center items-center gap-2 inline-flex",
                    !isWrongOTP
                      ? isRightOTP
                        ? CORRECT_TEXTBOX
                        : NORMAL_TEXTBOX
                      : WRONG_TEXTBOX,
                  ].join(" ")}
                  value={
                    fourthOTPDigit || fourthOTPDigit === 0
                      ? fourthOTPDigit.toString()
                      : ""
                  }
                  onKeyDown={(event: any) => {
                    if (event.key === "ArrowRight") {
                      fifthOTPInput.current.focus();
                    }

                    if (event.key === "ArrowLeft") {
                      thirdOTPInput.current.focus();
                    }
                    if (event.key === "Backspace") {
                      if (fourthOTPDigit !== null) {
                        setFourthOTPDigit(null);
                        buildOTP(4, null);
                      } else {
                        thirdOTPInput.current.focus();
                        setThirdOTPDigit(null);
                      }
                    }
                    if (!isNaN(parseInt(event.key))) {
                      if (fourthOTPDigit == null) {
                        setFourthOTPDigit(event.key);
                        buildOTP(4, parseInt(event.key));
                      } else {
                        if (fifthOTPInput && fifthOTPInput.current) {
                          setFifthOTPDigit(event.key);
                        }
                      }
                      fifthOTPInput.current.focus();
                    }
                  }}
                  minLength={0}
                  maxLength={1}
                  type="text"
                  id="fourthOTPDigit"
                />

                <input
                  ref={fifthOTPInput}
                  disabled={isRightOTP}
                  className={[
                    "w-10 h-10 text-center px-3 py-2 rounded-lg border justify-center items-center gap-2 inline-flex",
                    !isWrongOTP
                      ? isRightOTP
                        ? CORRECT_TEXTBOX
                        : NORMAL_TEXTBOX
                      : WRONG_TEXTBOX,
                  ].join(" ")}
                  value={
                    fifthOTPDigit || fifthOTPDigit === 0
                      ? fifthOTPDigit.toString()
                      : ""
                  }
                  onKeyDown={(event: any) => {
                    if (event.key === "ArrowRight") {
                      sixthOTPInput.current.focus();
                    }

                    if (event.key === "ArrowLeft") {
                      fourthOTPInput.current.focus();
                    }

                    if (event.key === "Backspace") {
                      if (fifthOTPDigit !== null) {
                        setFifthOTPDigit(null);
                        buildOTP(5, null);
                      } else {
                        fourthOTPInput.current.focus();
                        setFourthOTPDigit(null);
                      }
                    }
                    if (!isNaN(parseInt(event.key))) {
                      if (fifthOTPDigit == null) {
                        setFifthOTPDigit(event.key);
                        buildOTP(5, parseInt(event.key));
                      } else {
                        if (sixthOTPInput && sixthOTPInput.current) {
                          setSixthOTPDigit(event.key);
                          buildOTP(6, parseInt(event.key));
                        }
                      }
                      sixthOTPInput.current.focus();
                    }
                  }}
                  minLength={0}
                  maxLength={1}
                  type="text"
                  id="fifthOTPDigit"
                />

                <input
                  ref={sixthOTPInput}
                  disabled={isRightOTP}
                  className={[
                    "w-10 h-10 text-center px-3 py-2 rounded-lg border justify-center items-center gap-2 inline-flex",
                    !isWrongOTP
                      ? isRightOTP
                        ? CORRECT_TEXTBOX
                        : NORMAL_TEXTBOX
                      : WRONG_TEXTBOX,
                  ].join(" ")}
                  value={
                    sixthOTPDigit || sixthOTPDigit === 0
                      ? sixthOTPDigit.toString()
                      : ""
                  }
                  onKeyDown={(event: any) => {
                    if (event.key === "ArrowLeft") {
                      fifthOTPInput.current.focus();
                    }

                    if (event.key === "Backspace") {
                      setSixthOTPDigit(null);
                      buildOTP(6, null);
                      fifthOTPInput.current.focus();
                      setFifthOTPDigit(null);
                    }
                    if (!isNaN(parseInt(event.key))) {
                      if (sixthOTPDigit == null) {
                        setSixthOTPDigit(event.key);
                      }
                      buildOTP(6, parseInt(event.key));
                    }
                  }}
                  minLength={0}
                  maxLength={1}
                  type="text"
                  id="sixthOTPDigit"
                />
                <div className="w-4 h-4">
                  {isWrongOTP ? (
                    <button
                      onClick={() => {
                        setFirstOTPDigit(null);
                        setSecondOTPDigit(null);
                        setThirdOTPDigit(null);
                        setFourthOTPDigit(null);
                        setFifthOTPDigit(null);
                        setSixthOTPDigit(null);
                        setIsWrongOTP(false);
                        setIsRightOTP(false);
                        setOtpErrorMessage("");
                        sendEmailOTP();
                        firstOTPInput.current.focus();
                      }}
                    >
                      {isWrongOTP ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-4 h-4 text-red-700"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      ) : isRightOTP ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4 text-emerald-700"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 12.75l6 6 9-13.5"
                          />
                        </svg>
                      ) : (
                        <></>
                      )}
                    </button>
                  ) : isRightOTP ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4 text-emerald-700"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            {isWrongOTP && (
              <div className="w-72 text-center text-red-600 text-xs font-normal leading-5">
                {otpErrorMessage}
              </div>
            )}

            {isCodeExpired ? (
              <Button
                onClick={() => clearOTP()}
                size="sm"
                className="w-[80%] bg-indigo-700 text-white enabled:hover:bg-indigo-800 focus:ring-indigo-800 cursor-pointer"
              >
                <div className="text-center text-white text-sm font-medium leading-5 cursor-pointer">
                  Resend the code
                </div>
              </Button>
            ) : (
              <div className="w-80 text-center">
                <span className="text-gray-600 text-xs font-medium ">
                  Didn't get the email?{" "}
                </span>
                {seconds === 0 ? (
                  <span
                    onClick={() => clearOTP()}
                    className="text-blue-600 text-xs font-medium leading-5 cursor-pointer"
                  >
                    Resend
                    <br />
                  </span>
                ) : (
                  <span className="text-blue-600 text-xs font-medium cursor-pointer">
                    <br />
                    Resend in <span>{seconds}</span> seconds...
                    <br />
                  </span>
                )}
                <span className="text-gray-400 text-xs italic">
                  Can't find email ? Check your spam folder
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
