"use client";
import { useNavigate } from "react-router-dom";
import "./portal.css";
import { Dropdown } from "flowbite-react";


export default function UserIconDropDown({ user, isAdmin }: { user: any, isAdmin?: boolean }) {
  const navigate = useNavigate();
  return (
    <>
      <Dropdown.Item onClick={() => { }} className="font-bold">
        {user?.full_name}
      </Dropdown.Item>
      <Dropdown.Item onClick={() => { }}>
        {user?.email}
      </Dropdown.Item>
      {isAdmin && (
        <Dropdown.Item
          onClick={() => {
            navigate("/admin/users", { replace: true });
          }}
          className=""
        >
          Admin Console
        </Dropdown.Item>
      )}
      <Dropdown.Divider className="bg-gray-200" />
      <Dropdown.Item
        onClick={() => {
          sessionStorage.clear();
          localStorage.clear();
          navigate("/", { replace: true });
        }}
        className="text-red-500"
      >
        Sign out
      </Dropdown.Item>
    </>
  );
}
