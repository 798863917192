import './App.css';
import Dashboard from './components/Content/Dashboard/Dashboard';

function App() {
  // const [appName, setAppName] = useState("");

  // useEffect((): any => {
  //   let mounted = true;
  //   getList()
  //     .then((appName: any) => {
  //       if (mounted) {
  //         setAppName(appName);
  //       }
  //     })
  //   return () => mounted = false;
  // }, [])

  return (
    <div className="wrapper">
      <div>
        {/* <NavbarWithCTAButton /> */}
        <Dashboard />
      </div>
    </div>
  )

}

export default App;
