"use client";

import CircleLogo from "./CircleLogo";
import { Button } from "flowbite-react";
import { useEffect, useState } from "react";
import { getOrganizationList } from "../../../Utilities/getOrganizationList";
import { useNavigate } from "react-router-dom";
import { OrganisationList } from "./OrganizationsList";

export default function LoginSelectOrganization(props: any) {
  const [workSpaces, setWorkspaces] = useState<any[]>([]);
  const navigate = useNavigate();
  const email = localStorage.getItem("email");

  const getOrganizations = async () => {
    try {
      const response = await getOrganizationList();
      const { activeWorkspaces, invitedWorkspaces } = response;
      console.log("activeWorkspaces resp: ", activeWorkspaces);
      console.log("invitedWorkspaces resp: ", invitedWorkspaces);
      if (
        (activeWorkspaces && activeWorkspaces.length > 0) ||
        (invitedWorkspaces && invitedWorkspaces.length > 0)
      ) {
        const combinedWorkspaces = [
          ...(activeWorkspaces || []),
          ...(invitedWorkspaces || []),
        ];
        console.log("combinedWorkspaces: ", combinedWorkspaces);
        setWorkspaces(combinedWorkspaces);
        console.log("workSpaces:", workSpaces);
      } else {
        console.log("No workspaces found");
        setWorkspaces([]);
      }
    } catch (error) {
      console.error("Error fetching organizations:", error);
      setWorkspaces([]);
    }
  };
  useEffect(() => {
    getOrganizations();
  }, []);

  const noWorkspacesMessage = (
    <div className="text-center font-semibold text-gray-600">
      <p>You are not part of any workspace yet.</p>
      <p>Please contact your admin.</p>
    </div>
  );

  return (
    <div className="h-screen overflow-y-scroll flex flex-col items-center justify-center">
      <div className="flex flex-col items-center h-full w-[45rem] p-2 min-h-screen">
        <div className="flex flex-col items-center mt-6">
          <CircleLogo height={60} width={60} />
          <p className="font-bold text-4xl text-primary-500">Circle App</p>
        </div>
        <div className="flex flex-col justify-center p-2 text-center mt-2">
          <p className="font-medium text-gray-600">
            Welcome to your Workspaces
          </p>
          <p className="font-normal text-sm text-gray-600">
            Choose a workspace
          </p>
        </div>
        <div className="flex flex-col rounded-lg shadow bg-gray-100 justify-center p-8 mt-8 gap-4 w-full">
          <div className="flex flex-row items-center p-2">
            <p>Workspace(s) for&nbsp;</p>
            <p className="font-bold text-primary-500">{email}</p>
          </div>
          <div className="border-[0.05px] border-primary-400 my-2" />
          {workSpaces.length > 0 ? (
            <OrganisationList data={workSpaces} />
          ) : (
            noWorkspacesMessage
          )}
        </div>
        <div className="flex flex-col mt-8 text-center">
          <p className="text-md text-gray-500">Not seeing your workspace?</p>
          <div
            onClick={() => {
              sessionStorage.clear();
              localStorage.clear();
              navigate("/signin?flow=email", { replace: true });
            }}
          >
            <p className="text-indigo-500 hover:text-indigo-900 cursor-pointer underline italic">
              Try a different email
            </p>
          </div>
        </div>
      </div>
    </div>
  );

}
