import { FC } from "react";
import { Button } from "flowbite-react";

import deleteIcon from "../../assets/icons/deleteIcon.svg";
import RightDrawer from "./RightDrawer";

interface ConfirmationModalProps {
  open: boolean;
  toggleModal: () => void;
  text: string;
  confirmAction: () => void;
  cancelButtonText?: string;
  confirmButtonText?: string;
  highlightedText?: string;
}

const ConfirmationModal: FC<ConfirmationModalProps> = (props) => {
  const {
    open,
    toggleModal,
    text,
    confirmAction,
    cancelButtonText,
    confirmButtonText,
    highlightedText,
  } = props;
  return (
    <RightDrawer open={open} handleOpen={toggleModal}>
      <div className="mt-10">
        <h3 className="mt-4 text-base text-red-500">{text}</h3>
        {highlightedText && (
          <h3 className="text-base font-semibold text-primary-500 py-1 mb-4">{highlightedText}</h3>
        )}
        <div className="flex gap-4">
          <Button color="gray" onClick={toggleModal}>
            {cancelButtonText || "Cancel"}
          </Button>
          <Button color="failure" onClick={confirmAction}>
            {confirmButtonText || "Delete"}
          </Button>
        </div>
      </div>
    </RightDrawer>
  );
};

export default ConfirmationModal;
