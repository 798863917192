import axios from "axios";
import { INTERNAL_SERVER_ERROR } from "../Constants/MessageConstants";

const subdomain = window.location.host.split(".")[0];
const apiDomain =
  window.location.origin !== `http://${subdomain}.localhost:3001`
    ? process.env.REACT_APP_API_DOMAIN || "http://localhost:3000"
    : "http://localhost:3000";

export async function acceptUserInvite(
  workspaceId: string,
  invitationId: string,
  fullName: string
): Promise<any> {
  try {

    const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/invitations/${invitationId}/accept`;
    const response = await axios.post(
      url,
      { fullName },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log("RES:", response);
    const { oneTimeAuth } = response.data;
    return {
      status: "success",
      message: "Invitation accepted successfully.",
      oneTimeAuth: oneTimeAuth,
    };
  } catch (err) {
    console.error("Error accepting invitation:", err);
    alert("Error accepting invitation from server!");
    return {
      status: "error",
      message: INTERNAL_SERVER_ERROR,
    };
  }
}
