"use client";
import ArrowLeft from "./images/arrow-left.svg";
import "./login.css";
import { sendOTP } from "../../../Utilities/sendOTPMethod";
import { checkUser } from "../../../Utilities/checkUser";
import { useNavigate } from "react-router-dom";
import { REGEX_VALIDATION } from "../../../Constants/RegexConstants";
import { useState } from "react";
import { Button } from "flowbite-react";
import CircleLogo from "./CircleLogo";

interface ILoginSignUpWithEmail {
  setSignUpStep(val: number): void;
  setUserEmail(email: string): void;
  setIsExistingUser(isExisitingUser: boolean): void;
  userEmail: string;
  isExistingUser?: boolean;
  sendEmailOTP: () => void;
}

export default function LoginSignUpWithEmail(props: ILoginSignUpWithEmail) {
  const [invalidEmailErrorMessage, setInvalidEmailErrorMessage] =
    useState<string>("");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleSendEmailOTP = async () => {
    setIsProcessing(true); 
    try {
      const response = await sendOTP(props.userEmail);
      if (response?.status === 200) {
        props.setSignUpStep(2);
      } else {
        setInvalidEmailErrorMessage("Server error. Failed to send magic code!");
      }
    } catch (error) {
      setInvalidEmailErrorMessage("Server error. Failed to send OTP");
    } finally {
      setIsProcessing(false); 
    }
  };

  const handleKeyUp = (event: { key: string }) => {
    if (
      event.key === "Enter" &&
      props.userEmail &&
      props.userEmail.match(REGEX_VALIDATION)
    ) {
      handleSendEmailOTP();
    }
  };

  const handleClick = () => {
    handleSendEmailOTP();
  };
  

  return (
    <>
      <div className="w-[25rem] h-auto bg-white rounded-xl shadow-lg border border-gray-300 flex flex-col justify-between items-center gap-1 p-5">
        <div className="flex w-full justify-start">
          <Button
            onClick={() => {
              props.setUserEmail("");
              props.setSignUpStep(0);
              navigate("/");
            }}
            size="sm"
            className="flex justify-center space-x-3 items-center bg-white border border-gray-200 enabled:hover:bg-gray-200 ring-0 focus:ring-0 focus:border-primary-500 transition-colors ease-in-out duration-150"
          >
            <img src={ArrowLeft} alt="leftArrowImage" className="px-0.5" />
            <div className="text-md text-gray-600">Back</div>
          </Button>
        </div>
        <CircleLogo height={100} width={100} />
        <div className="self-stretch text-center h-100 flex flex-col justify-center items-center gap-4 my-10 text-gray-600">
          <div>
            <div className="text-2xl font-medium">Continue with Email</div>
            <div className="text-xs font-medium text-gray-400">
              We’ll email you a magic code for a password-free sign-in
            </div>
          </div>

          <input
            autoFocus
            value={props.userEmail}
            onChange={(event) => {
              props.setUserEmail(event.target.value);
              if (!event.target.value.match(REGEX_VALIDATION)) {
                setInvalidEmailErrorMessage("Please enter a valid email.");
              } else {
                setInvalidEmailErrorMessage("");
              }
            }}
            onKeyUp={handleKeyUp}
            type="text"
            id="email"
            className="placeholder:text-start placeholder:text-gray-300 placeholder:font-normal font-bold w-[80%] px-3 py-2 rounded-lg border border-gray-200 justify-center items-center gap-2 inline-flex"
            placeholder="Enter Email address"
          />
          {invalidEmailErrorMessage && (
            <div className="w-[80%] text-start text-red-600 text-xs font-normal">
              {invalidEmailErrorMessage}
            </div>
          )}
          {(!props.userEmail || !props.userEmail.match(REGEX_VALIDATION)) && (
              <Button className="w-[80%] bg-gray-300  enabled:hover:cursor-not-allowed transition-colors ease-in-out duration-150">
              <div className="text-md text-black">Send magic code</div>
            </Button>
          )}
          {props.userEmail && props.userEmail.match(REGEX_VALIDATION) && (
            <Button
              onClick={handleClick}
              isProcessing={isProcessing}
              disabled={isProcessing}
              className="w-[80%] bg-primary-500 text-white enabled:hover:bg-primary-700 focus:ring-indigo-800 transition-colors ease-in-out duration-150"
            >
              <div className="text-md text-white">Send magic code</div>
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
