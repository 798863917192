import { SELFIE_RULE_RESULT } from "./constants";

export interface Rule {
  ratingCheckUiText: string;
  ratingFunction: string;
  result?: SELFIE_RULE_RESULT;
}

export const uploadRules = [
  {
    ratingCheckUiText: "The photo should be of good resolution and quality.",
    ratingFunction: "getIsImageOfGoodQuality",
  },
  {
    ratingCheckUiText: "There should be exactly ONE recognizable face.",
    ratingFunction: "getIsFaceCountOne",
  },
  {
    ratingCheckUiText:
      "The selfie should be a portrait photo, showing only face and bust. No other parts.",
    ratingFunction: "getIsSelfieAPortraitPhoto",
  },
  {
    ratingCheckUiText: "All parts of the face must be clearly recognizable.",
    ratingFunction: "getAreAllFacePartsRecognizable",
  },
  {
    ratingCheckUiText: "The mouth must be closed.",
    ratingFunction: "getIsMouthClosed",
  },
  {
    ratingCheckUiText: "The eyes must be open and looking straight",
    ratingFunction: "getIsEyesLookingStraight",
  },
  {
    ratingCheckUiText: "The head must be facing straight.",
    ratingFunction: "getIsHeadStraight",
  },
  {
    ratingCheckUiText: "The face expression must be neutral.",
    ratingFunction: "getIsExpressionNeutral",
  },
];
