import { FC, useContext, useEffect, useRef, useState } from "react";
import Header from "../UserPortal/Header";
import Sidebar from "./Sidebar";
import TableOptions from "./TableOptions";
import AccountsTable from "./AccountsTable";
import { customTheme } from "../../../CommonUtilities/CustomThemes";
import { Flowbite, Button, Modal } from "flowbite-react";
import NoUsersScreen from "./NoUsersScreen";
import AddEditUserModal from "./AddEditUserModal";
import { ZulipContext } from "../../../context/ZulipContext";
import AddEditUserGroupModal from "./AddEditUserGroupModal";
import UserGroupTableOptions from "./UserGroupTableOptions";
import UserGroups from "./UserGroups";

const AdminConsole: FC = () => {
  const tableContainerRef = useRef<any>(null);
  const [tableHeight, setTableHeight] = useState<number>();
  const [searchText, setSearchText] = useState("");
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [openAddUserGroupModal, setOpenAddUserGroupModal] = useState(false);
  const [users, setUsers] = useState<any>([]);
  const [statusValue, setStatusValue] = useState<any>([]);
  const [roleValue, setRoleValue] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalText, setModalText] = useState("");

  const zulipContext = useContext(ZulipContext);

  const toggleAddUserModal = () => setOpenAddUserModal((old) => !old);
  const toggleAddUserGroupModal = () => setOpenAddUserGroupModal((old) => !old);
  const isForUserGroup: any = window.location.pathname === "/admin/groups";

  useEffect(() => {
    if (tableContainerRef?.current?.offsetHeight) {
      setTableHeight(tableContainerRef?.current?.offsetHeight);
    }
  }, [zulipContext.users, zulipContext.userGroups]);

  const handleInviteSuccess = (response: any) => {
    setOpenModal(true);
    let responseString = '';

    Object.entries(response).forEach(([key, value]) => {
      let valueParsed = typeof value === 'string' ? JSON.parse(value) : value;

      const textColorClass = valueParsed.success ? 'text-green-500' : 'text-red-500';

      const message = `Email: ${valueParsed.email} -  ${valueParsed.message}`;

      responseString += `<div class="${textColorClass} flex items-center justify-center gap-2">${message}</div>`;
    });

    setModalText(responseString);
  };

  return (
    <Flowbite theme={{ theme: customTheme }}>
      <div className="w-full h-screen flex flex-col">
        <Header />
        <div className="flex flex-1 h-full w-full overflow-hidden">
          <Sidebar />
          <div className="flex-1 flex flex-col p-4 pb-0 overflow-auto">
            {!isForUserGroup && (
              <TableOptions
                searchText={searchText}
                setSearchText={setSearchText}
                usersCount={zulipContext.users.length}
                onClickAddUser={toggleAddUserModal}
                setStatusValue={setStatusValue}
                setRoleValue={setRoleValue}
                roleValue={roleValue}
                statusValue={statusValue}
              />
            )}
            {isForUserGroup && (
              <UserGroupTableOptions
                searchText={searchText}
                setSearchText={setSearchText}
                userGroupsCount={10}
                onClickAddUserGroup={toggleAddUserGroupModal}
              />
            )}
            <div ref={tableContainerRef} className="flex-1 overflow-y-auto">
              {!isForUserGroup &&
                zulipContext.users?.length > 0 &&
                tableHeight ? (
                <AccountsTable
                  tableHeight={tableHeight}
                  searchText={searchText}
                  users={users}
                  statusValue={statusValue}
                  roleValue={roleValue}
                />
              ) : isForUserGroup &&
                zulipContext.userGroups.length > 0 &&
                tableHeight ? (
                <UserGroups tableHeight={tableHeight} searchText={searchText} />
              ) : (
                <NoUsersScreen
                  onClickAddUser={
                    isForUserGroup
                      ? toggleAddUserGroupModal
                      : toggleAddUserModal
                  }
                  isForUserGroup={isForUserGroup}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <AddEditUserModal
        open={openAddUserModal}
        toggleModal={toggleAddUserModal}
        existingUserEmails={users.map((user: any) => user.email)}
        onInviteSuccess={handleInviteSuccess}
      />
      <AddEditUserGroupModal
        open={openAddUserGroupModal}
        toggleModal={toggleAddUserGroupModal}
      />
      <Modal show={openModal} size="md" onClose={() => setOpenModal(false)} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            {/* Render modalText as HTML (safely) */}
            <div dangerouslySetInnerHTML={{ __html: modalText }} />
            <div className="flex justify-center gap-4 my-2">
              <Button className="bg-primary-500 hover:bg-primary-700" onClick={() => setOpenModal(false)}>
                OK
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Flowbite>
  );
};

export default AdminConsole;
