import { FC } from "react";
import { Rule } from "./uploadRules";
import { SELFIE_RULE_RESULT } from "./constants";
import checkIcon from "./images/check.svg";
import crossIcon from "./images/cancel.svg";
import infoIcon from "./images/info-icon.svg";

interface SelfieRulesProps {
  rules: Rule[];
  selfieResult: SELFIE_RULE_RESULT;
}

const FAILED_TEXT = "There are error with the captured selfie please re-take";
const PASS_TEXT = "Photo looks good! Review and approve";

const SelfieRules: FC<SelfieRulesProps> = ({ rules, selfieResult }) => {
  const bgColor = (rule: Rule) => {
    if (rule.result === "pass") {
      return "bg-green-100";
    }
    if (rule.result === "fail") {
      return "bg-red-100";
    }
    return "bg-gray-100";
  };

  const getIcon = (result: SELFIE_RULE_RESULT) => {
    if (result === SELFIE_RULE_RESULT.PASS) return checkIcon;
    if (result === SELFIE_RULE_RESULT.FAIL) return crossIcon;
    return infoIcon;
  };

  const isFailed = selfieResult === SELFIE_RULE_RESULT.FAIL;
  const textColor = isFailed ? "text-red-600" : "text-green-600";

  return (
    <div className="mt-2">
      <div
        className={`${textColor} text-[12px] font-medium border-b border-gray-200 pb-4 px-6 text-center`}
      >
        {isFailed ? FAILED_TEXT : PASS_TEXT}
      </div>
      <div className="flex items-center gap-3 justify-center text-center mt-4">
        <div className="text-[14px] font-medium">Quality Checks - </div>
        <div className={`${textColor} text-[14px] font-medium`}>
          {isFailed ? "Failed" : "Passed"}
        </div>
      </div>
      <div className="flex flex-col gap-3 w-full mt-3">
        {rules.map((rule: Rule) => (
          <div
            key={rule.ratingCheckUiText}
            className={`${bgColor(rule)} px-4 py-1 flex items-center gap-2`}
          >
            <div className="min-w-[24px]">
              <img
                src={getIcon(rule.result!)}
                alt="info-icon"
                height={16}
                width={16}
              />
            </div>
            <div className="text-gray-900 text-12 leading-[18px]">
              {rule.ratingCheckUiText}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelfieRules;
