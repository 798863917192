import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { consumePasswordlessCode } from "supertokens-web-js/recipe/thirdpartypasswordless";
import { INTERNAL_SERVER_ERROR, RESPONSE_OK_STATUS } from '../../../Constants/MessageConstants';
import { IConsume } from '../../../CommonInterfaces/IConsume';
import { checkUser } from '../../../Utilities/checkUser';

export default function VerifyMagicLink() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleMagicLinkClicked = async () => {
      try {
        let response: IConsume | any = await consumePasswordlessCode();
        console.log("resp ::", response);
        if (response.status === RESPONSE_OK_STATUS) {
          if (!sessionStorage.getItem("stUserId")) {
            sessionStorage.setItem("stUserId", response.user.id);
            sessionStorage.setItem("stTenantId", response.user.tenantIds.join(","));
          }
          if (!response.createdNewUser) {
            const isUserPresentinDBResponse: any = await checkUser(response.user.email);
            if (isUserPresentinDBResponse === INTERNAL_SERVER_ERROR) { // user exist in central db, but not in local, redirecting to sign in
              sessionStorage.setItem("email", response.user.email);
              localStorage.setItem("signUpStep", "3");
              navigate('/signin');
            } else { // user exist in local db, redirecting to portal
              navigate('/selectOrganization');
            }
          } else { // new user neither in local nor in central, redirecting to sign in
            localStorage.setItem("signUpStep", "3");
            sessionStorage.setItem("email", response.user.email);
            navigate('/signin');

          }
        } else {
          sessionStorage.clear();
          navigate('/');
        }

      } catch (err: any) {
        window.alert(JSON.stringify(err));
        if (err.isSuperTokensGeneralError === true) {
          navigate('/');
        } else {
          window.alert("Oops! Something went wrong.");
          navigate('/');
        }
      }
    };
    handleMagicLinkClicked();
  }, [navigate]);

  return (
    <div>
      Verifying magic link...
    </div>
  );
}

