import { Button, Label, Modal, TextInput, Textarea } from "flowbite-react";
import { FC, useContext, useState, useEffect } from "react";
import {ZulipContext} from "../../../context/ZulipContext";
import {isValidHandle} from "./utils/helper";
import AddUserMembers from "./AddUserMembers";
import RightDrawer from "../../Reusable/RightDrawer";

interface AddEditUserGroupModalProps {
  open: boolean;
  toggleModal: () => void;
  selectedUserGroup?: any;
}

const AddEditUserGroupModal: FC<AddEditUserGroupModalProps> = ({
  open,
  toggleModal,
  selectedUserGroup,
}) => {
  const zulipContext = useContext(ZulipContext);
  const [name, setName] = useState(selectedUserGroup?.name || "");
  const [description, setDescription] = useState(
    selectedUserGroup?.description || ""
  );
  const [shouldDIsplayAddMembersPopup, setShouldDIsplayAddMembersPopup] =
    useState(false);
  const [isDuplicateName, setIsDuplicateName] = useState(false);

  useEffect(() => {
    if (selectedUserGroup) {
      setName(selectedUserGroup?.name);
      setDescription(selectedUserGroup?.description);
    }
  }, [selectedUserGroup]);

  const toggleAddUserMemberModal = () => {
    setShouldDIsplayAddMembersPopup((old) => !old);
  };

  const updateUserGroups = async () => {
    try {
      const response = await zulipContext.client.users.groups.update({
        name,
        description,
        id: selectedUserGroup?.id,
      });
      if (response.result === "error") {
        alert(response.msg);
      } else {
        zulipContext.fetchUserGroups(zulipContext.client);
        toggleModal();
      }
    } catch (error) {
      console.log("🚀 ~ updateUserGroups ~ error:", error);
    }
  };

  const checkIfGroupNameAlreadyExists = async () => {
    if (
      !selectedUserGroup &&
      zulipContext.userGroups.filter(
        (group: any) => group?.name?.toLowerCase() === name?.toLowerCase()
      ).length > 0
    ) {
      return setIsDuplicateName(true);
    } else {
      if (selectedUserGroup) {
        await updateUserGroups();
      } else {
        toggleAddUserMemberModal();
      }
      return setIsDuplicateName(false);
    }
  };

  const onSubmitForm = async (e: any) => {
    e.preventDefault();
    checkIfGroupNameAlreadyExists();
    console.log("submit");
  };

  const isSubmitDisabled = !name?.trim() || !isValidHandle(name);

  return (
    <>
      <RightDrawer open={open} handleOpen={toggleModal}>
        <div className="space-y-6">
          <h3 className="text-xl font-medium text-gray-900 dark:text-white">
            {selectedUserGroup ? "Edit User Group" : `Create User Group`}
          </h3>
          <form onSubmit={onSubmitForm}>
            <div className="mt-5">
              <div className="mb-2 block">
                <Label htmlFor="name" value="Name:" />
              </div>
              <TextInput
                id="name"
                value={name}
                onChange={(event) => setName(event.target.value)}
                required
              />
              {isDuplicateName && (
                <div>
                  <text className="text-xs text-red-500">
                    Group with this name already exist in your realm.
                  </text>
                </div>
              )}
              <div>
                <text className="text-xs text-gray-500">
                  You'll use display name to get the group's attention. It
                  should be all lowercase, with no spaces.
                </text>
              </div>
              {name && !isValidHandle(name) && (
                <div>
                  <text className="text-xs text-red-500">
                    Handle is not valid.
                  </text>
                </div>
              )}
            </div>
            <div className="mt-5">
              <div className="mb-2 block">
                <Label htmlFor="description" value="Purpose:&nbsp;" />
              </div>
              <Textarea
                id="description"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                required
              />
              {!selectedUserGroup && (
                <div>
                  <text className="text-xs text-gray-500">
                    What is this group about?
                  </text>
                </div>
              )}
            </div>
            <div className="flex items-center justify-between mt-10">
              <text className="text-xs text-gray-500">
                {selectedUserGroup ? "" : "Step 1 of 2"}
              </text>
              <div className="w-[100px]">
                <Button
                  type="submit"
                  className="bg-indigo-700 w-full"
                  color="primary"
                  disabled={isSubmitDisabled}
                >
                  {`${selectedUserGroup ? "Save" : "Next"}`}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </RightDrawer>

      {shouldDIsplayAddMembersPopup && (
        <AddUserMembers
          open={shouldDIsplayAddMembersPopup}
          toggleModal={toggleAddUserMemberModal}
          toggleParentModal={toggleModal}
          name={name}
          description={description}
        />
      )}
    </>
  );
};

export default AddEditUserGroupModal;
