import axios from "axios";

const subdomain = window.location.host.split(".")[0];
const apiDomain: string =
  window.location.origin !== `http://${subdomain}.localhost:3001`
    ? process.env.REACT_APP_API_DOMAIN || "http://localhost:3000"
    : "http://localhost:3000";

export async function inviteUsers(emails: string[], role: string): Promise<any> {
  const accessToken = localStorage.getItem("key");
  const workspaceId = localStorage.getItem("workspaceId");
  const zulipToken = `Zulip ${accessToken}`;

  if (!accessToken || !workspaceId) {
    alert("Please log in to continue or refresh the page.");
    return;
  }

  const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/invitations`;
  const encodedEmails = emails.map(email => encodeURIComponent(email));
  const bodyContent = `workspaceRole=${role}&emails=${encodedEmails.join(",")}`;

  try {
    const result = await axios.post(
      url,
      bodyContent,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: zulipToken,
        },
      }
    );
    return result.data;
  } catch (err: any) {
    console.log('inviteUsers API error :', err);
    throw err;
  }
}