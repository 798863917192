import { FC, ReactNode, useRef } from "react";
import "./RightDrawer.css";

interface RightDrawerProps {
  open: boolean;
  handleOpen: () => void;
  children: ReactNode;
}

const RightDrawer: FC<RightDrawerProps> = ({ open, handleOpen, children }) => {
  const drawerRef = useRef<any>(null);

  return (
    <div ref={drawerRef} className={`drawer ${open ? "open" : ""}`}>
      <div className="p-6">
        <div
          className="flex justify-end cursor-pointer"
          onClick={handleOpen}
          style={{ fontSize: "24px" }}
        >
          &times;
        </div>
        {children}
      </div>
    </div>
  );
};

export default RightDrawer;
