'use client';
import { useState } from 'react';
import './login.css';
import { ORG_NAME_BLANK_STRING_ERROR_MSG, ORG_NAME_LENGTH_LESS_THAN_THREE_ERROR_MSG } from '../../../Constants/MessageConstants';
import { IUserAccount } from '../../../CommonInterfaces/IUserAccount';
import { createAccount } from '../../../Utilities/createAccount';
import { useNavigate } from 'react-router-dom';
import CircleLogo  from './CircleLogo';
import { Button,Card } from 'flowbite-react';


interface ILoginSignUpAddOrgDetails {
    setSignUpStep(val: number): void;
    email: string;
}

export default function LoginSignUpAddOrgDetails(props: ILoginSignUpAddOrgDetails) {
    const navigate = useNavigate();

    const [accountType, setAccountType] = useState<string>("Organization");
    const [orgName, setOrgName] = useState<string>("");
    const [orgNameErrorMessage, setOrgNameErrorMessage] = useState<string>("");

    const createUserAccount = async () => {
        const userAccount: IUserAccount = {
            accountname: accountType == 'Personal'? `${sessionStorage.getItem("fullName")}`: orgName,
            email: props.email ? props.email : `${sessionStorage.getItem("email")}`,
            username: `${sessionStorage.getItem("fullName")}`,
            stUserId: `${sessionStorage.getItem("stUserId")}`,
            stTenantId: `${sessionStorage.getItem("stTenantId")}`
        }
        // const createAccountResponse: any = await createAccount(userAccount);
        await createAccount(userAccount);
        navigate('/portal', { replace: true });
    }

    return (
        <>
            <Card className="w-[25rem] h-[30rem] m-2 py-3 bg-white rounded-lg shadow border border-gray-300 flex flex-col justify-center items-center gap-5">
                <div className='flex flex-col justify-center items-center'>
                    <CircleLogo height={100} width={100}/>
                    <div className="w-80 flex flex-col justify-start items-center gap-4">
                        <div className="w-80 text-center text-emerald-700 text-lg font-semibold leading-6">We are almost there!</div>
                        <div className="w-72 h-20 bg-gray-50 rounded-lg border border-gray-300 flex flex-col justify-center items-center gap-3">
                            <div className="w-52 text-center text-gray-900 text-sm font-semibold leading-5">I am creating this account for</div>
                            <div className="w-60 h-auto gap-2">
                                <div onClick={() => {
                                    setAccountType("Organization");
                                }}
                                    className="radio w-28 h-4 rounded-lg justify-start items-start gap-2 inline-flex">
                                    <div className="w-4 h-4 justify-center items-center gap-2.5 flex">
                                        <div className={accountType === "Organization" ? "w-4 h-4 bg-gray-50 rounded-full border-2 border-blue-600" :
                                            "w-4 h-4 bg-gray-50 rounded-lg border border-gray-300"} />
                                    </div>
                                    <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                                        <div className="text-gray-900 text-sm font-medium leading-4">Organization</div>
                                    </div>
                                </div>
                                <div onClick={() => {
                                    setAccountType("Personal");
                                }}
                                    className="radio w-28 h-4 rounded-lg justify-start items-start gap-2 inline-flex">
                                    <div className={accountType === "Personal" ? "w-4 h-4 bg-gray-50 rounded-full border-2 border-blue-600" :
                                        "w-4 h-4 bg-gray-50 rounded-lg border border-gray-300"} />
                                    <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                                        <div className="text-gray-900 text-sm font-medium leading-4">Personal use</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-[80%] h-auto gap-3 flex flex-col justify-center items-center">
                            {accountType === "Organization" ?
                                <div>
                                    <div className=" text-gray-600 text-xs font-medium leading-4 text-center">
                                    Enter your organization / team's short name
                                    </div>
                                    <input
                                        autoFocus
                                        value={orgName}
                                        onChange={(event: any) => {
                                            setOrgName(event.target.value);
                                            setOrgNameErrorMessage(!(event.target.value)
                                                ? ORG_NAME_BLANK_STRING_ERROR_MSG : event.target.value.trim().length < 3 ?
                                                    ORG_NAME_LENGTH_LESS_THAN_THREE_ERROR_MSG : "")
                                        }}
                                        onKeyUp= {(event) => {
                                            if(event.key === 'Enter')  {
                                                createUserAccount()
                                            } 
                                        }}
                                        type="text" id="orgName"
                                        className="text-center w-full h-10 p-3 rounded-lg border border-gray-400 justify-start items-center gap-2 inline-flex"
                                        placeholder={accountType === "Organization" ? "Organization Name" : "Person Name"} />
                                </div>
                                :
                                <div className="text-left text-gray-600 text-xs font-medium leading-4">
                                    
                                </div>
                            }
                            
                                
                                {orgNameErrorMessage &&
                                    <div className="w-56 text-red-600 text-xs font-normal leading-5">
                                        {orgNameErrorMessage}
                                    </div>
                                }
                                {!orgNameErrorMessage ?
                                    <Button  onClick={() => createUserAccount()} className=" w-56 bg-indigo-700 text-white enabled:hover:bg-indigo-800 focus:ring-indigo-800">
                                        <div className='text-center text-white text-sm font-medium leading-2'>
                                            Continue
                                        </div>
                                    </Button>
                                    :
                                    <Button className="w-56 bg-gray-300  enabled:hover:cursor-not-allowed enabled:hover:bg-gray-400">
                                        <div className="text-center text-gray-800 text-sm font-medium">Continue</div>
                                    </Button>
                                }
                        </div>
                    </div>
                </div>
            </Card>
        </>

    )
}


