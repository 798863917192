import {
    INTERNAL_SERVER_ERROR
} from "../Constants/MessageConstants";
import { IUserAccount } from "../CommonInterfaces/IUserAccount";
import axios from "axios";
import { SIGN_UP_URL } from "../Constants/URLConstants";

const apiDomain: string = window.location.origin !== "http://localhost:3001" ?
  process.env.REACT_APP_API_DOMAIN || "http://localhost:3001" : "http://localhost:3001";


export async function createAccount(userAccount: IUserAccount): Promise<any> {
    try {
        let userAccountCreateResponse: any = await axios.post(`${apiDomain}${SIGN_UP_URL}`, userAccount)
            .then((response) => {
                console.log(response);
            });

        console.log("userAccountCreateResponse : ", userAccountCreateResponse);
        
    } catch (err: any) {
        if (err.isSuperTokensGeneralError) {
            return err.message;
        } else {
            return INTERNAL_SERVER_ERROR;
        }
    }
}