import { FC, useEffect, useRef, useState } from "react";
import flipCameraIcon from "./images/flip-camera.svg";
import {
  CAMERA_ERROR,
  CAMERA_VIEW,
  INFO_SCREEN,
  SELFIE_RULE_RESULT,
  ratingResult,
} from "./constants";
import InfoScreen from "./InfoScreen";
import { Button, Spinner } from "flowbite-react";
import Header from "./Header";
import SelfieRules from "./SelfieRules";
import { Rule, uploadRules } from "./uploadRules";
import { useSearchParams } from "react-router-dom";
import {
  getQualityCheckParameters,
  submitAvatar,
} from "../../../Utilities/avatar";
import Webcam from "react-webcam";
import SelfieRater,{RatingsScoreComputationResult} from "@circle-apps/selfie-rating";
import { consumeAuthKey } from "../../../Utilities/consumeAuthKey";

const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: "user",
};

interface Authdatatype{
  token:string;
  workspaceId:string;
  circleUserId:string;
}

const ClickSelfie: FC = () => {
  const [cameraView, setCameraView] = useState<CAMERA_VIEW>(CAMERA_VIEW.USER);
  const [infoScreen, setInfoScreen] = useState<INFO_SCREEN>();
  const [clickedSelfie, setClickedSelfie] = useState<string>("");
  const [selfieResult, setSelfieResult] = useState<SELFIE_RULE_RESULT>();
  const [selfieRules, setSelfieRules] = useState<Rule[]>(uploadRules);
  const [loading, setLoading] = useState(false);
  const imageRef = useRef<any>();
  const [searchParams] = useSearchParams();
  const oneTimeKey = searchParams.get("key");
  const [authdata, setAuthdata] = useState<Authdatatype>({token:'',circleUserId:'',workspaceId:''});
  const [cameraError, setCameraError] = useState("");
  const webcamRef = useRef(null);
 const [SelfieRaterObj,setSelfieRaterObj] = useState<SelfieRater>();

 const createSelfieRater = async () => {
  console.log('createSelfieRater');
  setLoading(true);
  const SelfieRaterObj = await SelfieRater.getInstance({
    debugLogs: true,
  });
  console.log('SelfieRaterObj', SelfieRaterObj);
  setSelfieRaterObj(SelfieRaterObj);
  setLoading(false);
};
  useEffect(() => {
    createSelfieRater();
    const getAccountInfo = async () => {
      try {
        setLoading(true);
        const result = (await consumeAuthKey(oneTimeKey!)) as any;
        if (!result) {
          throw new Error("Invalid key");
        }
        if(result?.token){
        setAuthdata({...result,
        ['token']:result?.token,
        ['workspaceId']:result?.workspaceId,
        ['circleUserId']:result?.circleUserId        
      });
        setLoading(false);
        }
      } catch (e) {
        console.log("error",e);
        setLoading(false);
        setAuthdata({token:'',circleUserId:'',workspaceId:''});
        setInfoScreen(INFO_SCREEN.TOKEN_FAILURE);
      }
    };
      if (!infoScreen && !authdata?.token) {
        getAccountInfo();
      }
     else {
      setInfoScreen(INFO_SCREEN.ERROR);
    }

    return ()=>{
      SelfieRaterObj?.closeModels();
    }
  }, []);

  // console.log("object",object);
  useEffect(() => {
    const fetchRules = async () => {
      try {
        setLoading(true);
        const response = await getQualityCheckParameters(authdata?.token);
        setSelfieRules(response?.data);
        setLoading(false);
      } catch (e: any) {
        setLoading(false);
        setSelfieRules(uploadRules);
      }
    };
    if (authdata?.token) fetchRules();
  }, [authdata?.token]);

  useEffect(() => {
    if (!clickedSelfie) {
      setSelfieResult(undefined);
      setSelfieRules(uploadRules);
    }
  }, [clickedSelfie]);

  const onShowInfoScreen = (
    screen: INFO_SCREEN | undefined,
    error?: string
  ) => {
    setInfoScreen(screen);
    if (error) setCameraError(error);
  };

  const onFlipCamera = () => {
    setCameraView((old) => {
      if (old === CAMERA_VIEW.USER) return CAMERA_VIEW.ENVIRONMENT;
      return CAMERA_VIEW.USER;
    });
  };

  const handleTakePhoto = () => {
    const pictureSrc = (webcamRef?.current as any)?.getScreenshot();
    setClickedSelfie(pictureSrc);
  };

  const onContinue = async () => {
    if (imageRef.current) {
      setLoading(true);
      imageRef.current.src = clickedSelfie;
      imageRef.current.onload = async () => {
        SelfieRaterObj?.analyzeSelfie({
          imageElement: imageRef.current,
          resultCallback: (result: RatingsScoreComputationResult) => {
            console.log('result', result);
          },
        });

        let updatedRules = [] as Rule[];
        let previousResultPassed = true;
        try {
          selfieRules.forEach((rating_check) => {
            const ratingFunction = rating_check.ratingFunction;
            let result = undefined;
            if(previousResultPassed){
              result =
              typeof (SelfieRaterObj as any)[ratingFunction] === "function"
                ? (SelfieRaterObj as any)[ratingFunction]()
                : undefined;
                previousResultPassed = result && previousResultPassed;
            }
            updatedRules.push({
              ...rating_check,
              result: ratingResult(result),
            });
          });
          setSelfieRules(updatedRules);
          setSelfieResult(() =>
            updatedRules.some((rule) => rule.result === SELFIE_RULE_RESULT.FAIL)
              ? SELFIE_RULE_RESULT.FAIL
              : SELFIE_RULE_RESULT.PASS
          );
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      };
    }
  };

  const onRetake = () => setClickedSelfie("");

  const onUpload = async () => {
    try {
      setLoading(true);
      if(authdata?.workspaceId && authdata?.circleUserId && authdata?.token){
        await submitAvatar(authdata, {
          dataUrl: clickedSelfie,
          photoType:'selfie'
        });
        setLoading(false);
        onShowInfoScreen(INFO_SCREEN.SUCCESS);
      }
    } catch (e) {
      console.log("Error :",e);
      setLoading(false);
      onShowInfoScreen(INFO_SCREEN.ERROR);
    }
  };

  const isFailed = selfieResult === SELFIE_RULE_RESULT.FAIL;

  return (
    <div className="flex flex-col items-center justify-center w-full">
      {loading && !clickedSelfie ? (
        <div className="absolute z-[1000] inset-1/2 flex flex-col items-center transform -translate-x-1/2 -translate-y-1/2">
          <Spinner aria-label="spinner-from-web" color="purple" size="lg" />
        </div>
      ) : (
        <div className="max-w-[600px]">
          {!infoScreen ? (
            <>
              <div className="py-4">
                <Header
                  clickedSelfie={clickedSelfie}
                  onShowInfoScreen={onShowInfoScreen}
                  selfieResult={selfieResult}
                  onRetake={onRetake}
                />
                <div className={`${clickedSelfie ? "px-12" : "px-6"}`}>
                  <div className="mt-4 relative">
                    {clickedSelfie ? (
                      <img
                        src={clickedSelfie}
                        alt="seflie"
                        ref={imageRef}
                        style={{
                          height: 400,
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <div>
                        <Webcam
                          audio={false}
                          height={400}
                          ref={webcamRef}
                          width={400}
                          screenshotFormat="image/jpeg"
                          videoConstraints={{
                            ...videoConstraints,
                            facingMode: cameraView,
                          }}
                          onUserMediaError={(e: any) => {
                            const err = e ? JSON.stringify(e) : CAMERA_ERROR;
                            setCameraError(err);
                          }}
                          mirrored={true}
                        />
                        <div className="flex justify-between mt-4 items-center px-4 w-full">
                          <div
                            className=" cursor-pointer"
                            onClick={onFlipCamera}
                          >
                            <img src={flipCameraIcon} alt="camera flip" />
                          </div>
                          <div
                            className="w-[64px] h-[64px] rounded-full bg-red-600"
                            role="button"
                            onClick={(e: any) => {
                              e.preventDefault();
                              handleTakePhoto();
                            }}
                          />
                          <div />
                        </div>
                      </div>
                    )}
                  </div>
                  {clickedSelfie && !selfieResult && (
                    <div className="mt-8 flex justify-center">
                      <div className="flex gap-4">
                        <Button
                          color="light"
                          className="h-[34px] w-[120px]"
                          onClick={onRetake}
                        >
                          Retake
                        </Button>
                        <Button
                          className="h-[34px] w-[120px] bg-indigo-700 enabled:hover:bg-indigo-800"
                          onClick={onContinue}
                          isProcessing={loading}
                        >
                          Continue
                        </Button>
                      </div>
                    </div>
                  )}
                  {selfieResult && (
                    <>
                      <SelfieRules
                        rules={selfieRules}
                        selfieResult={selfieResult}
                      />
                    </>
                  )}
                </div>
              </div>
              {selfieResult && (
                <div className="mt-5 border-b border-t mb-4 border-gray-200 bg-gray-50 px-12 py-6">
                  {isFailed ? (
                    <Button
                      className="h-[34px] bg-indigo-700 enabled:hover:bg-indigo-800 w-full"
                      onClick={onRetake}
                    >
                      Retake
                    </Button>
                  ) : (
                    <div className="flex justify-center gap-4">
                      <Button
                        color="light"
                        className="h-[34px] w-full"
                        onClick={onRetake}
                      >
                        Retake
                      </Button>
                      <Button
                        className="h-[34px] w-full bg-indigo-700 enabled:hover:bg-indigo-800"
                        onClick={onUpload}
                        isProcessing={loading}
                      >
                        Upload Selfie
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </>
          ) : (
            <InfoScreen
              infoScreen={infoScreen}
              onShowInfoScreen={onShowInfoScreen}
              error={cameraError}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ClickSelfie;
