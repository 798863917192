import { INTERNAL_SERVER_ERROR } from "../Constants/MessageConstants";
import axios from "axios";

// REACT_APP_API_DOMAIN=http://localhost:3001
const apiDomain: string =
  window.location.origin !== "http://localhost:3001"
    ? process.env.REACT_APP_API_DOMAIN || "http://localhost:3001"
    : "http://localhost:3001";

export async function consumeAuthKey(oneTimeAuthKey: string): Promise<any> {
  // const url = `${apiDomain}api/v2/authKey/consume?oneTimeAuthKey=${oneTimeAuthKey}`;

  // http://localhost:3000/api/v2/auth/oneTimeUseAuthTokens/{ota}/consume
  const url = `${apiDomain}/api/v2/auth/oneTimeUseAuthTokens/${oneTimeAuthKey}/consume`;

  try {
    const result = await axios.put(url, {}, { withCredentials: true });
    if (!result) {
      return undefined;
    }

    return result.data;
  } catch (err: any) {
  }
}
