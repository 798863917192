import { FC } from "react";
import { Button, Modal } from "flowbite-react";

import deleteIcon from "../../assets/icons/deleteIcon.svg";
import { HiOutlineCheckCircle } from "react-icons/hi2";

interface SuccessModalProps {
  open: boolean;
  toggleModal: () => void;
  text: string;
  highlightedText?: string;
}

const SuccessModal: FC<SuccessModalProps> = props => {
  const {
    open,
    toggleModal,
    text,
    highlightedText
  } = props;
  return (
    <Modal show={open} size="md" popup onClose={toggleModal}>
      <Modal.Header />
      <Modal.Body>
        <div className="flex flex-col items-center">
          <HiOutlineCheckCircle size={40} color="green" />
          <h3 className="m-4 font-semibold text-center text-gray-500">
            {text}
          </h3>
          {highlightedText &&
            <h3 className=" mb-4 text-lg font-bold">
              {highlightedText}
            </h3>}
          <div className="flex justify-center gap-4">
            <Button size="sm" onClick={toggleModal} className="bg-primary-500 text-white hover:!bg-primary-700">
              {"OK"}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
