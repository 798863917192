import { GetRowIdParams } from "ag-grid-community";
import moment from "moment";
import { REGEX_VALIDATION } from "../../../../Constants/RegexConstants";

export const getRowId = (params: GetRowIdParams) => {
  return params?.data?.id;
};

export const isValidHandle = (handle: string) => {
  return /^[a-z]+(-[a-z]+)*$/.test(handle);
}


// Mapping function for circleUsers
const mapCircleUsers = (circleUsersList: any) => {
  const circleUsers = circleUsersList.map((user: any) => {
    const newUserObj = {
      email: user.email,
      invited_user_id: user.circleUserId,
      avatar_version: 1,
      role: user.role,
      is_admin: user.role === "Admin",
      is_owner: user.role === "Owner",
      is_guest: false,
      is_billing_admin: false,
      is_bot: false,
      full_name: user.fullName,
      timezone: "Asia/Kolkata",
      is_active: user.status === "Active",
      is_invited: false,
      is_multiuse: false,
      date_joined: user.dateJoined,
      expiry_date: null,
      delivery_email: user.email,
      avatar_url: null
    };
    return newUserObj;
  });
  return circleUsers;
};

// Mapping function for invitedUsers
const mapInvitedUsers = (invitedUsersList: any) => {
  const invitedUsers = invitedUsersList.map((user: any) => {
    const newUserObj = {
      email: user.inviteeEmail,
      invited_user_id: user.userInvitationId,
      avatar_version: 1,
      is_admin: false,
      is_owner: false,
      is_guest: false,
      is_billing_admin: false,
      role: user.role,
      is_bot: false,
      full_name: user.inviteeEmail.split("@")[0],
      timezone: "Asia/Kolkata",
      is_active: false,
      is_invited: true,
      is_multiuse: false,
      date_joined: user.dateInvited,
      expiry_date: "",
      delivery_email: user.inviteeEmail,
      avatar_url: null
    };
    return newUserObj;
  });
  return invitedUsers;
};

// Main function to map both circleUsers and invitedUsers
export const mapAllUsers = (userInvites: any) => {
  const circleUsers = mapCircleUsers(userInvites.circleUsers);
  const invitedUsers = mapInvitedUsers(userInvites.invitedUsers);
  return [...circleUsers, ...invitedUsers];
};

export const checkInvitationExpiredOrNot = (invitationExpireTime: any) => {
  if (moment(invitationExpireTime).isBefore(moment.now())) {
    return true;
  } else {
    return false;
  }
}

export const validateEmail = (email: string) => {
  if (email === "" || email.match(REGEX_VALIDATION)) {
    return true;
  }
  return false;
}