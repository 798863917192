import axios from "axios";

export async function      fetchApiKey(email: any, password: any, subdomain: any) {
  const baseURL = `${subdomain}.${process.env.REACT_APP_ZULIP_BASE_URL}`;
  
  const url = `https://${baseURL}/api/v1/fetch_api_key?username=${email}&password=${password}`;
  try {
    const result = await axios.post(url);
    return result.data;
  } catch (error: any) {
    return error.message;
  }
}
