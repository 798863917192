import { Avatar, Button, Modal, TextInput } from "flowbite-react";
import { FC, useContext, useEffect, useState } from "react";
import { ZulipContext } from "../../../context/ZulipContext";
import { HiSearch, HiOutlineUserAdd } from "react-icons/hi";
import AddUserMembers from "./AddUserMembers";
import RightDrawer from "../../Reusable/RightDrawer";
import { ImCross } from "react-icons/im";

interface EditUserMembersProps {
  open: boolean;
  toggleModal: () => void;
  toggleParentModal: () => void;
  name: string;
  description: string;
  selectedUserGroup?: any;
}

const EditUserMembers: FC<EditUserMembersProps> = ({
  open,
  toggleModal,
  toggleParentModal,
  name,
  description,
  selectedUserGroup,
}) => {
  const zulipContext = useContext(ZulipContext);
  const [membersIdList, setMembersIdList] = useState<any>([]);

  const getUserGroupMembers = async () => {
    try {
      const response = await zulipContext.client.users.groups.members.retrieve({
        id: selectedUserGroup.id,
      });
      if (response.result === "error") {
        alert(response.msg);
      } else {
        setMembersIdList(response.members);
      }
    } catch (error) {
      console.log("🚀 ~ getUserGroupMembers ~ error:", error);
    }
  };

  const [searchText, setSearchText] = useState<string>("");

  const getExistingMembers = () => {
    let availableEmails = [];
    if (!membersIdList || membersIdList.length === 0) {
      return [];
    }
    for (let i = 0; i < membersIdList.length; i++) {
      availableEmails = zulipContext.users.filter((user: any) => {
        if (user.user_id && user.user_id === membersIdList[i]) {
          return user.email;
        }
      });
    }
    if (searchText) {
      return availableEmails.filter((user: any) =>
        user.email.includes(searchText)
      );
    }
    return availableEmails;
  };

  const [showRemoveButton, setShowRemoveButton] = useState<number>(-1);
  const [showAddUserMemberModal, setShowAddUserMemberModal] = useState(false);

  const toggleAddUserMemberModal = () => {
    setShowAddUserMemberModal((old: any) => !old);
  };

  const onDeleteMember = async (user: any) => {
    try {
      if (user) {
        const response = await zulipContext.client.users.groups.members.update({
          id: selectedUserGroup.id,
          delete: [user.user_id],
        });
        if (response.result === "error") {
          alert(response.msg);
        } else {
          zulipContext.fetchUserGroups(zulipContext.client);
          getExistingMembers();
        }
      }
    } catch (error) {
      console.log("🚀 ~ onDeleteMember ~ error:", error);
    }
  };

  useEffect(() => {
    getUserGroupMembers();
  }, [zulipContext.userGroups]);

  return (
    <RightDrawer open={open} handleOpen={toggleModal}>
      <div className="space-y-2">
        <h3 className="text-xl font-medium text-gray-900 dark:text-white">
          {selectedUserGroup
            ? `Members ${selectedUserGroup.members.length}`
            : "Add Members"}
        </h3>
        <div className="w-full">
          <TextInput
            icon={HiSearch}
            id="search"
            name="search"
            placeholder="Search"
            size={32}
            type="search"
            style={{ height: "37px" }}
            className="focus:border-indigo-700"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div
          className="flex flex-row hover:bg-gray-100 items-center w-full h-[50px] cursor-pointer"
          onClick={() => {
            toggleAddUserMemberModal();
          }}
        >
          <div className="flex ml-2 mr-2 border border-gray-500 rounded w-[30px] h-[30px] bg-gray-300 justify-center items-center">
            <HiOutlineUserAdd />
          </div>
          <div>
            <p>Add Member</p>
          </div>
        </div>
        {getExistingMembers().map((user: any, index: number) => {
          return (
            <div
              className="flex flex-row w-full h-[50px] hover:bg-gray-100 justify-between items-center"
              onMouseEnter={() => setShowRemoveButton(index)}
              onMouseLeave={() => setShowRemoveButton(-1)}
            >
              <div className="flex items-center">
                <div className="flex ml-2 mr-2  border border-gray-500 rounded w-[30px] h-[30px] bg-gray-300 justify-center items-center">
                  <Avatar
                    alt="User settings"
                    img={user?.avatar_url}
                    size="sm"
                  />
                </div>
                <text className="">{`${user?.email}`}</text>
              </div>
              {showRemoveButton === index && (
                <Button
                  className="text-black"
                  onClick={() => {
                    onDeleteMember(user);
                  }}
                >
                  <ImCross color="red" />
                </Button>
              )}
            </div>
          );
        })}
        {searchText && getExistingMembers().length === 0 && (
          <div className="flex flex-col w-full h-[70px] justify-center items-center">
            <p>{`No matches found for ${searchText}`}</p>
            <Button
              className="text-black border border-gray-700 mt-2"
              onClick={() => {
                toggleAddUserMemberModal();
                setSearchText("");
              }}
            >
              Add Member
            </Button>
          </div>
        )}
      </div>
      <AddUserMembers
        open={showAddUserMemberModal}
        toggleModal={toggleAddUserMemberModal}
        selectedUserGroup={selectedUserGroup}
        toggleParentModal={() => { }}
      />
    </RightDrawer>
  );
};

export default EditUserMembers;
