"use client";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createAuthKey } from "../../../Utilities/createAuthKey";
import { FiAlertTriangle } from "react-icons/fi";
import { HiOutlineExternalLink } from "react-icons/hi";

export const OrganisationList = (props: any) => {
  const navigate = useNavigate();

  const handleAcceptInvite = (
    workspaceId: string,
    invitationId: string,
    invitationToken: string
  ) => {
    // Open the accept invitation URL in a new tab
    const url = `/acceptInvitation?workspaceId=${workspaceId}&invitationId=${invitationId}&invitationToken=${invitationToken}`;
    console.log("accept invite url:", url);
    window.open(url, "_blank");
  };

  const selectOrganization = async (
    workspaceRole: string,
    workspaceSubdomain: string,
    zulipApiKey: string,
    workspaceName: string
  ) => {
    const email = localStorage.getItem("email");

    sessionStorage.setItem("workspaceRole", workspaceRole);
    localStorage.setItem("workspaceName", workspaceName);

    const authKey = await createAuthKey(zulipApiKey);
    const realmUrl = `${process.env.REACT_APP_HOST_PROTOCOL}://${workspaceSubdomain}.${process.env.REACT_APP_HOSTNAME}/portal?workspaceName=${workspaceName}&oneTimeAuthKey=${authKey.oneTimeUseAuthTokenId}`;
    console.log("Subdomain URL: ", realmUrl);

    // If only one active workspace and no pending invites, redirect to the default workspace in same tab
    if (activeWorkspaces.length === 1 && invitedWorkspaces.length === 0) {
      window.location.href = realmUrl;
    } else {
      // Else Open the realm URL in a new tab and wait for user to choose
      window.open(realmUrl, "_blank");
    }
  };

  // Separate active and invited workspaces
  const activeWorkspaces = props.data.filter(
    (workspace: any) => workspace.circleUserId
  );
  const invitedWorkspaces = props.data.filter(
    (workspace: any) => !workspace.circleUserId
  );

  // Check if there is only one active workspace and no pending invites
  useEffect(() => {
    if (activeWorkspaces.length === 1 && invitedWorkspaces.length === 0) {
      const item = activeWorkspaces[0];

      selectOrganization(
        item.workspaceRole,
        item.workspaceSubdomain,
        item.zulipApiKey,
        item.workspaceName
      );
    }
  }, [activeWorkspaces, invitedWorkspaces]);

  const [showOpenText, setShowOpenText] = useState(-1);
  const [showAcceptText, setShowAcceptText] = useState(-1);

  return (
    <div>
      <div className="overflow-y-auto max-h-[40vh]">
        {props.data.map((item: any, index: number) => {
          // Determine if the workspace is active or invited
          const isActive = Boolean(item.circleUserId);
          const bgColor = isActive ? "bg-primary-100" : "bg-gray-200";
          const textColor = isActive ? "text-primary-500/80" : "text-gray-800";

          return (
            <div
              key={index}
              className={`hover:${bgColor} cursor-pointer m-2 rounded ${bgColor} border hover:shadow transition-shadow ease-in-out duration-200`}
              onMouseEnter={() => {
                setShowOpenText(isActive ? index : -1);
                setShowAcceptText(!isActive ? index : -1);
              }}
              onMouseLeave={() => {
                setShowOpenText(-1);
                setShowAcceptText(-1);
              }}
              onClick={() => {
                if (isActive) {
                  // Open the subdomain url in a new tab for active workspaces
                  selectOrganization(
                    item.workspaceRole,
                    item.workspaceSubdomain,
                    item.zulipApiKey,
                    item.workspaceName
                  );
                } else {
                  // Handle invitation for invited workspaces
                  handleAcceptInvite(
                    item.workspaceId,
                    item.userInvitationId,
                    item.invitationToken
                  );
                }
              }}
            >
              <div className="flex flex-row p-2 items-center justify-between">
                <div className="flex items-center justify-start">
                  <div className="flex flex-col w-1.5/12 justify-center text-center">
                    <div
                      className={`flex flex-col w-[3rem] h-[3rem] justify-center text-center rounded-md border-2 ${
                        isActive ? "bg-primary-400" : "bg-gray-500"
                      }`}
                    >
                      <span className="font-bold text-xl text-white">
                        {item.workspaceName.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center justify-between gap-2 ml-2">
                    <span className={`font-bold text-xl ${textColor}`}>
                      {item.workspaceName}
                    </span>
                    {/* Display label only if invited */}
                    {!isActive && (
                      <span className="flex items-center justify-center text-xs gap-1 px-2 py-1 bg-primary-200 opacity-50 rounded-2xl text-gray-800">
                        <FiAlertTriangle /> Pending Invitation
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex flex-row items-center">
                  {isActive ? (
                    <>
                      {showOpenText === index ? (
                        <span className="mr-2 text-black">Open</span>
                      ) : null}
                      <HiOutlineExternalLink />
                    </>
                  ) : (
                    <>
                      {showAcceptText === index ? (
                        <span className="mr-2 ">Accept Invite</span>
                      ) : null}
                      <HiOutlineExternalLink />
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
