'use client';
import { useState } from 'react';
import CircleLogoTop from './images/Circle_Logo.svg';
import './login.css';
import CircleLogo  from './CircleLogo';
import { Button,Card } from 'flowbite-react';

interface ILoginSignUpAddUserDetails {
    setSignUpStep(val: number): void;
}

export default function LoginSignUpAddUserDetails(props: ILoginSignUpAddUserDetails) {
    const [fullName, setFullName] = useState<string>("");
    localStorage.clear();
    return (
        <>
            <Card className="w-[25rem] h-[25rem] m-2 py-3 bg-white rounded-lg shadow border border-gray-300 flex flex-col justify-center items-center gap-5 ">
                <div className='flex flex-col justify-center items-center'>
                    <CircleLogo height={100} width={100}/>
                    <div className="flex flex-col justify-start items-center gap-4">
                        <div className="w-80 text-center text-emerald-700 text-lg font-semibold leading-6">We are almost there!</div>
                        <div className="w-60 text-center text-gray-600 text-xs font-medium leading-5">
                            Confirm your full name.</div>
                        <input
                            autoFocus
                            value={fullName}
                            onChange={(event: any) => {
                                setFullName(event.target.value);
                                sessionStorage.setItem("fullName", event.target.value);

                            }}
                            onKeyUp= {(event) => {
                                if(event.key === 'Enter')  {
                                    props.setSignUpStep(4)
                                } 
                            }}
                            type="text" id="fullName"
                            className="w-[80%] p-2 rounded-lg border border-gray-400 placeholder:text-center text-center placeholder:text-gray-400 font-semibold"
                            placeholder="Full Name" />
                        {fullName ?
                            <Button  onClick={() => props.setSignUpStep(4)} className="w-[80%] bg-indigo-700 text-white enabled:hover:bg-indigo-800 focus:ring-indigo-800">
                                <div className='text-center text-white text-sm font-medium leading-2'>
                                    Continue
                                </div>
                            </Button>
                            :
                            <Button className="w-[80%] bg-gray-300  enabled:hover:cursor-not-allowed enabled:hover:bg-gray-400">
                                <div className="text-center text-gray-800 text-sm font-medium">Continue</div>
                            </Button>
                        }
                    </div>
                </div>
            </Card>

        </>

    )
}


