import { Button, Spinner } from "flowbite-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CircleLogo from "../LoginPage/CircleLogo";
import { acceptUserInvite } from "../../../Utilities/acceptInvitation";
import { ValidateInvitation } from "../../../Utilities/ValidateInvitation";

import {
  INVITATION_NOT_FOUND,
  INVITATION_EXPIRED_OR_REVOKED,
  INVITATION_ACCEPTED_OR_INVALID_USER,
  INVITATION_ACCEPTED_OR_VALID_USER,
  INVITATION_PENDING_AND_VALID,
  INTERNAL_SERVER_ERROR,
} from "../../../Constants/MessageConstants";

const AcceptInvitation = () => {
  const [fullName, setFullName] = useState("");
  const [shouldEnableButton, setShouldEnableButton] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState("");
  const [isValidInvitation, setIsValidInvitation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const checkIfAllValuesAreValid = () => {
    if (fullName !== "") {
      setShouldEnableButton(true);
    } else {
      setShouldEnableButton(false);
    }
  };

  useEffect(() => {
    checkIfAllValuesAreValid();
  }, [fullName]);

  useEffect(() => {
    // Invitation validation
    const verifyInvitation = async () => {
      try {
        setIsLoading(true);
        setLoadingStatus("Please wait while we verify your invitation...");
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const workspaceId = query.get("workspaceId");
        const invitationId = query.get("invitationId");
        const invitationToken = query.get("invitationToken");

        if (workspaceId && invitationId && invitationToken) {
          const result = await ValidateInvitation(
            workspaceId,
            invitationId,
            invitationToken
          );
          switch (result) {
            case INVITATION_PENDING_AND_VALID:
              setIsValidInvitation(true);
              break;
            case INVITATION_ACCEPTED_OR_VALID_USER:
              setErrorMessage("You have already accepted this invitation.");
              setIsValidInvitation(false);
              break;
            default:
              setErrorMessage(result);
              setIsValidInvitation(false);
              break;
          }
        } else {
          setErrorMessage(
            "Invalid workspaceId, invitationId, or invitationToken."
          );
          setIsValidInvitation(false);
        }
      } catch (error) {
        setErrorMessage("Failed to verify invitation.");
        setIsValidInvitation(false);
      } finally {
        setIsLoading(false);
      }
    };

    verifyInvitation();
  }, []);

  function useQuery() {
    return new URLSearchParams(document.location.search);
  }

  const query = useQuery();

  // Get the invitation details
  const getInvitationDetails = async (
    workspaceId: string,
    invitationId: string,
    fullName: string
  ): Promise<string | null> => {
    try {
      const result = await acceptUserInvite(
        workspaceId,
        invitationId,
        fullName
      );
      const { oneTimeAuth } = result;
      console.log('oneTimeAuth', oneTimeAuth)

      if (oneTimeAuth.workspaceSubdomain && oneTimeAuth.oneTimeUseAuthTokenId) {
        const { oneTimeUseAuthTokenId, workspaceSubdomain  } = oneTimeAuth;
        const realmUrl = `${
          process.env.REACT_APP_HOST_PROTOCOL
        }://${workspaceSubdomain}.${
          process.env.REACT_APP_HOSTNAME
        }/portal?oneTimeAuthKey=${oneTimeUseAuthTokenId}`;
        return realmUrl;
      } else {
        setErrorMessage(INTERNAL_SERVER_ERROR);
        setIsValidInvitation(false);
        return null; // Return null if oneTimeAuth is not available
      }
    } catch (error) {
      console.error("Error accepting invitation:", error);
      setErrorMessage(INTERNAL_SERVER_ERROR);
      setIsValidInvitation(false);
      return null; // Return null if an error occurs
    }
  };

  // Accept Invitation
  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const workspaceId = query.get("workspaceId");
      const invitationId = query.get("invitationId");

      if (workspaceId && invitationId) {
        try {
          const realmUrl = await getInvitationDetails(
            workspaceId,
            invitationId,
            fullName
          );
          if (realmUrl) {
            window.open(realmUrl);
            setIsLoading(true);
            // window.location.href = realmUrl;
            setIsLoading(false);
          } else {
            setErrorMessage("Error processing invitation");
            setIsValidInvitation(false);
          }
        } catch (error) {
          // Handle error from getInvitationDetails function
          setErrorMessage("Error processing invitation");
          setIsValidInvitation(false);
        }
      } else {
        setErrorMessage("Invalid workspaceId and/or invitationId");
        setIsValidInvitation(false);
      }
    } catch (error) {
      setErrorMessage("Failed to accept invitation.");
      setIsValidInvitation(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-row items-center my-5">
        <CircleLogo height={80} width={80} />
        <h1 className="font-bold text-3xl text-primary-500">Circle App</h1>
      </div>
      <div className="flex flex-col items-center justify-center">
        {isLoading ? (
          <div className="flex flex-col items-center justify-center text-center">
            <Spinner aria-label="circle spinner" color="purple" size="lg" />
            <p className="mt-4 text-lg text-primary-500">{loadingStatus}</p>
          </div>
        ) : (
          <div className="flex flex-col items-center w-full border-0.5 p-8 rounded-md bg-gray-100 shadow">
            {isValidInvitation ? (
              <>
                <div className="flex flex-col p-2 w-full">
                  <h2 className="mb-2">Full Name</h2>
                  <input
                    type="text"
                    required
                    autoComplete="off"
                    name="organizationName"
                    className="border-gray-200 rounded font-semibold text-lg w-full"
                    onChange={(event) => {
                      setFullName(event.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col p-2 mt-4 mb-4">
                  {!shouldEnableButton ? (
                    <Button className="bg-gray-300  enabled:hover:cursor-not-allowed enabled:hover:bg-gray-400">
                      Accept
                    </Button>
                  ) : (
                    <Button
                      className="bg-primary-500 text-white hover:bg-primary-700"
                      onClick={onSubmit}
                    >
                      Accept
                    </Button>
                  )}
                  <p className="text-center mt-4 text-sm italic whitespace-normal text-gray-400">
                    By clicking Accept, you agree to our
                    <a href="" className="text-primary-400">
                      Terms of Service
                    </a>{" "}
                    <br /> and that you have read our
                    <a href="" className="text-primary-400">
                      Provacy Policy
                    </a>
                  </p>
                </div>
              </>
            ) : (
              <div className="flex flex-col items-center justify-center gap-4">
                <p className="text-red-500">{errorMessage}</p>
                <Button className="bg-primary-500 text-white hover:bg-primary-700">
                  <a href="">Try different email?</a>
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AcceptInvitation;
