import { createContext, useEffect, useState } from "react";
// @ts-ignore
import zulip from "zulip-js-dev";
import { mapAllUsers } from "../components/Content/AdminConsole/utils/helper";
import { consumeAuthKey } from "../Utilities/consumeAuthKey";
import { getUserInvitations } from "../Utilities/getUserInvitations";

const defaultProvider = {
  user: null,
  client: true,
  users: [],
  config: () => Promise.resolve(),
  fetchUsers: () => { },
  fetchUserGroups: () => { },
  userGroups: null,
  setUserGroups: () => { },
  getAppUrl: () => {
    return "";
  },
};

interface IZulipContext {
  user: any;
  client: any;
  users: any;
  config: () => Promise<any>;
  fetchUsers: (zulipClient: any) => void;
  fetchUserGroups: (zulipClient: any) => void;
  userGroups: any;
  setUserGroups: (userGroups: any) => void;
  getAppUrl: () => string;
}

const ZulipContext = createContext<IZulipContext>(defaultProvider);

function ZulipProvider({ children }: any) {
  const [client, setClient] = useState<any>();
  const [user, setUser] = useState<any>();
  const [users, setUsers] = useState<any>([]);
  const [userGroups, setUserGroups] = useState<any>([]);

  function useQuery() {
    return new URLSearchParams(document.location.search);
  }

  const query = useQuery();

  const setupZulip = async () => {
    const oneTimeAuthKey = query.get("oneTimeAuthKey");
    let email = localStorage.getItem("email");
    let key = localStorage.getItem("key");
    let subdomain = window.location.host.split(".")[0];

    if (oneTimeAuthKey) {
      const data = await consumeAuthKey(oneTimeAuthKey);


      if (!data) {
        console.log("no consumeAuthKey data");
        return null;
      } else {
        console.log('consumeAuthKey data: ', data);
      }

      email = data.email;
      key = data.apiKey;
      subdomain = data.workspaceSubdomain;
      let inviteToken = data.token
      let workspaceId = data.workspaceId
      localStorage.setItem("inviteToken", data.token as string);
      localStorage.setItem("workspaceId", data.workspaceId as string);
      localStorage.setItem("workspaceName", data.workspaceName as string);
      localStorage.setItem("workspaceRole", data.workspaceRole as string);

    }


    const realm = `https://${subdomain}.${process.env.REACT_APP_ZULIP_BASE_URL}`;

    if (email && key) {
      localStorage.setItem("email", email as string);
      localStorage.setItem("key", key as string);

      const config: any = {
        username: email,
        realm: realm,
        apiKey: key,
      };
      console.log("🚀 ~ setupZulip ~ config:", config)

      const zulipClient: any = await zulip(config);

      const currentUser: any = await zulipClient.users.me.getProfile();
      console.log("🚀 ~ setupZulip ~ currentUser:", currentUser)

      setUser(currentUser);

      setClient(zulipClient);

      fetchUsers(zulipClient);
      fetchUserGroups(zulipClient);
    }
  };

  const fetchUsers = async (zulipClient: any) => {
    try {
      const allUsers = await getUserInvitations();
      const users = mapAllUsers(allUsers);

      setUsers(
        users.map((user: any, index: number) => {
          user.id = index + 1;
          return user;
        })
      );
    } catch (error) {
      console.log("🚀 ~ fetchUsers ~ error:", error);
    }
  };

  const fetchUserGroups = async (zulipClient: any) => {
    try {
      const response = await zulipClient.users.groups.retrieve();
      setUserGroups(
        response.user_groups.filter((group: any) => !group.is_system_group)
      );
    } catch (error) {
      console.log("🚀 ~ fetchUserGroups ~ error:", error);
    }
  };

  useEffect(() => {
    async function setup() {
      await setupZulip();
    }

    setup();
  }, []);

  const getAppUrl = () => {
    const subdomain = window.location.host.split(".")[0];
    const email = query.get("email") || localStorage.getItem("email");
    const key = query.get("key") || localStorage.getItem("key");
    if (!email || !key) return "";
    const url = `tauri://home?v=1&email=${email}&subDomain=${subdomain}&key=${key}`;
    return url;
  };

  const values = {
    user,
    client,
    users,
    config: setupZulip,
    fetchUsers,
    fetchUserGroups,
    userGroups,
    setUserGroups,
    getAppUrl,
  };

  return (
    <ZulipContext.Provider value={values}>{children}</ZulipContext.Provider>
  );
}

export { ZulipContext, ZulipProvider };
