import {consumeCode} from "supertokens-web-js/recipe/passwordless";
import {
  EXPIRED_USER_INPUT_CODE_ERROR,
  FAILED_LOGIN,
  INCORRECT_USER_INPUT_CODE_ERROR,
  SIGN_IN_SUCCESS,
  SIGN_UP_SUCCESS,
  WRONG_OTP_ERROR_MESSAGE,
  OLD_OTP_ERROR_MESSAGE,
  INTERNAL_SERVER_ERROR,
  RESPONSE_OK_STATUS
} from "../Constants/MessageConstants";
import {IConsume} from "../CommonInterfaces/IConsume";
import axios from "axios";

export async function verifyOTP(
  magicCode: string,
  email: string,
  // verificationCodeRequestId: string
): Promise<any> {
  try {
    const apiDomain: string =
      window.location.origin !== "http://localhost:3001"
        ? process.env.REACT_APP_API_DOMAIN || "http://localhost:3000"
        : "http://localhost:3000";


    const magicCodeId = localStorage.getItem("passwordlessMagicCodeId");

    const response = await axios.put(
      `${apiDomain}/api/v2/auth/passwordless/magicCodes/${magicCodeId}/verify`,
      { magicCode: magicCode }
    );

    localStorage.setItem("accessToken", response.data.accessToken);
    localStorage.setItem("email", email);

    return response;
  } catch (err: any) {
    if (err.isSuperTokensGeneralError) {
      return err.message;
    } else {
      return err.response;
    }
  }
}

export async function verifyOTPOld(
  otp: string,
  verificationCodeRequestId: string
): Promise<string> {
  try {
    sessionStorage.clear();
    let response: IConsume | any = await consumeCode({
      userInputCode: otp
    });
    if (response.status === RESPONSE_OK_STATUS) {
      if (!sessionStorage.getItem("stUserId")) {
        sessionStorage.setItem("stUserId", response.user.id);
        sessionStorage.setItem("stTenantId", response.user.tenantIds.join(","));
      }
      if (response.createdNewUser) {
        return SIGN_UP_SUCCESS;
      } else {
        return SIGN_IN_SUCCESS;
      }
    } else if (response.status === INCORRECT_USER_INPUT_CODE_ERROR) {
      sessionStorage.clear();
      return WRONG_OTP_ERROR_MESSAGE;
    } else if (response.status === EXPIRED_USER_INPUT_CODE_ERROR) {
      sessionStorage.clear();
      return OLD_OTP_ERROR_MESSAGE;
    } else {
      sessionStorage.clear();
      return FAILED_LOGIN;
    }
  } catch (err: any) {
    if (err.isSuperTokensGeneralError) {
      return err.message;
    } else {
      return INTERNAL_SERVER_ERROR;
    }
  }
}
