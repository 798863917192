import { ColDef } from "ag-grid-community";

export enum ROLE_VALUES {
  ADMIN = 200,
  OWNER = 100,
  MEMBER = 400
}

export const ROLE_NAMES = {
  Owner: "Owner",
  Admin: "Admin",
  Member: "Member"
};

export const ROLE_OPTIONS = [
  { label: "All", value: 0, id: 1 },
  { label: "Owner", value: ROLE_VALUES.OWNER, id: 2 },
  { label: "Admin", value: ROLE_VALUES.ADMIN, id: 3 },
  { label: "Member", value: ROLE_VALUES.MEMBER, id: 4 },
];

export enum STATUS_VALUES {
  ALL = "all",
  ACTIVE = "active",
  INVITED = "invited",
  DEACTIVATED = "deactivated"
}

export const STATUS_NAMES = {
  active: "Active",
  invited: "Invited",
  deactivated: "Deactivated"
};

export const STATUS_OPTIONS = [
  { label: "All", value: STATUS_VALUES.ALL, id: 1 },
  { label: "Active", value: STATUS_VALUES.ACTIVE, id: 2 },
  { label: "Invited", value: STATUS_VALUES.INVITED, id: 3 },
  { label: "Deactivated", value: STATUS_VALUES.DEACTIVATED, id: 4 }
];

export const USERGROUPS_STATUS_OPTIONS = [
  { label: "Active", value: "active", id: 1 },
  { label: "Deactived", value: "deactivated", id: 2 },
];

export const defaultColDef: ColDef = {
  flex: 1,
  menuTabs: [],
  sortable: true,
  suppressFillHandle: true,
  suppressMovable: true
};
