import axios from "axios";

const apiDomain: string =
  window.location.origin !== "http://localhost:3001"
    ? process.env.REACT_APP_API_DOMAIN || "http://localhost:3000"
    : "http://localhost:3000";
export async function createOrganization(data: any): Promise<any> {
  const body = {
    api_key: process.env.REACT_APP_API_KEY,
    ...data
  };
  const accessToken = localStorage.getItem("accessToken");
  const bearerToken = `Bearer ${accessToken}`;
  try {
    const result = await axios.post(
      `${apiDomain}/api/organizations/create`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerToken
        }
      }
    );

    return result;
  } catch (err: any) {
    
      return {
        status : err.response.status,
        data: err.response.data
      };
    
  }
}
