export const ORG_NAME_BLANK_STRING_ERROR_MSG: string = "Org name is mandatory";
export const ORG_NAME_LENGTH_LESS_THAN_THREE_ERROR_MSG: string = "Org name must be equals or greater than 3 letters";
export const ORG_NAME_DUPLICATE_ERROR_MSG: string = "This name is already taken up. Please choose a different one";
export const INCORRECT_USER_INPUT_CODE_ERROR: string = "INCORRECT_USER_INPUT_CODE_ERROR";
export const EXPIRED_USER_INPUT_CODE_ERROR: string = "EXPIRED_USER_INPUT_CODE_ERROR";
export const RESPONSE_OK_STATUS: string = "OK";
export const SIGN_UP_SUCCESS: string = "New User Signed up Successfully";
export const SIGN_IN_SUCCESS: string = "Logged in Successfully";
export const FAILED_LOGIN: string = "Login failed. Please try again";
export const WRONG_OTP_ERROR_MESSAGE: string = "Invalid magic code. Please try again!";
export const OLD_OTP_ERROR_MESSAGE: string = "Expired magic code. Please regenerate new new one.";
export const INTERNAL_SERVER_ERROR: string = "Oops! Something went wrong.";
export const INVITATION_ACCEPTED_OR_VALID_USER: string = "Invitation already accepted and valid user.";
export const INVITATION_PENDING_AND_VALID: string = "Invitation pending and valid.";
export const INVITATION_NOT_FOUND: string = "Invitation not found!";
export const INVITATION_EXPIRED_OR_REVOKED: string = "Invitation expired or revoked";
export const INVITATION_ACCEPTED_OR_INVALID_USER: string = "Invtation already accepted or invalid user";
