import { Avatar, Dropdown, Navbar } from "flowbite-react";
import { FC, useContext, useEffect, useState } from "react";
import CircleLogoWhite from "./../LoginPage/images/Circle_Logo_White.svg";
import UserIconDropDown from "./UserIconDropDown";
import { ZulipContext } from "../../../context/ZulipContext";

const Header: FC = () => {
  const [showUserIconDropdown, setShowUserIconDropdown] =
    useState<boolean>(false);
  const [workspaceName, setWorkspaceName] = useState<string | null>(null);

  const zulipContext = useContext(ZulipContext);
  const userObject = zulipContext.user;

  useEffect(() => {
    const storedWorkspaceName = localStorage.getItem("workspaceName");
    setWorkspaceName(storedWorkspaceName);

  }, []);

  return (
    <Navbar fluid className="bg-primary-500 shadow-md ">
      <div className="w-full flex justify-between">
        <a href={window.location.search !== "" ? `/portal${window.location.search}` : `/portal`} className="flex items-center">
          <div className="flex flex-col w-[2rem] h-[2rem] justify-center text-center bg-gray-100 rounded-md">
            <p className="font-extrabold text-2xl text-primary-500 bg-red">
              {workspaceName?.charAt(0).toUpperCase()}
            </p>
          </div>
          <p className="ml-2 text-white text-xl font-bold">{workspaceName}</p>
        </a>
        <div className="flex gap-3">
          <Dropdown
            arrowIcon={false}
            inline
            label={<Avatar alt="User settings" img={userObject?.avatar_url} rounded />}
            onClick={() => setShowUserIconDropdown(!showUserIconDropdown)}
            className="text-gray-700 bg-gray-100"
          >
            <UserIconDropDown user={userObject} />
          </Dropdown>
        </div>
      </div>
    </Navbar>
  );
};

export default Header;
