'use client';

import Business3DYoung from './images/Business3DYoung.png';
import CircleLogo  from './CircleLogo';
import { MAC_APP_DOWNLOAD_URL, WINDOWS_APP_DOWNLOAD_URL } from '../../../Constants/URLConstants';
import svgDownloadIcon from "./../Dashboard/images/arrow_downward_alt.svg";
import macIcon from "./../Dashboard/images/mac.png";
import windowsIcon from "./../Dashboard/images/windows.png";


export default function AppDownload() {
    localStorage.clear();
    return (
        <>
            <div className="w-[25rem] h-[35rem] m-2 py-3 bg-white rounded-lg shadow border border-gray-300 flex flex-col justify-between items-center gap-5">
                <CircleLogo height={100} width={100}/>
                <div className="flex flex-col gap-y-1 mt-2 w-60 text-center text-gray-600">
                    <div className="leading-9 text-indigo-700 text-3xl font-bold">Circle App</div>
                    <div className="w-full text-center text-black text-lg font-medium leading-6">
                  Download the Circle App
                </div>
                <div className="w-full h-auto flex justify-center gap-2">
                  <a
                    title="Download for Mac"
                    className="w-1/2 flex justify-evenly cursor-pointer bg-sky-100 items-center rounded-xl p-3"
                    href={MAC_APP_DOWNLOAD_URL}
                  >
                    <img alt="" className="w-10 h-10" src={macIcon} />
                    <div className="">
                      <img alt="" className="w-8 h-8" src={svgDownloadIcon} />
                    </div>
                  </a>
                  <a
                    title="Download for Windows"
                    className="w-1/2 flex justify-evenly cursor-pointer bg-sky-100 items-center rounded-xl p-3"
                    href={WINDOWS_APP_DOWNLOAD_URL}
                  >
                    <img alt="" className="w-10 h-10" src={windowsIcon} />
                    <div className="">
                      <img className="w-8 h-8" alt="" src={svgDownloadIcon} />
                    </div>
                  </a>
                </div>
                </div>
                <img alt='users-cartoon-placeholder' width="188" height="246" className='-scale-x-100' src={Business3DYoung} />
            </div>
        </>
    )
}


