'use client';
import './portal.css'
import CircleLogo  from './../LoginPage/CircleLogo';

import { Card,Spinner } from 'flowbite-react';

interface LoadingSpinnerDetails {
    text: string;
}

export default function LoadingSpinner(props: LoadingSpinnerDetails) {

    return (
        <>
            <div className="w-full h-screen bg-violet-700 flex items-center justify-center">
                <Card className="w-[80%] md:w-[25rem] bg-white h-auto">
                    <div className='flex flex-col justify-center items-center gap-16'>
                        <div className='p-1'>
                            <CircleLogo height={100} width={100}/>
                        </div>
                        <div className="w-80 text-center text-gray-600  font-bold leading-5 text-lg">{props.text}</div>
                        <Spinner
                            id="spinner"
                            aria-label="Extra large spinner example"
                            size="xl"
                            color="purple"
                        />
                    </div>
                </Card>
            </div>
        </>

    )
}


