import {
    INTERNAL_SERVER_ERROR
} from "../Constants/MessageConstants";
import axios from "axios";
import { CHECK_USER_EXIST_URL } from "../Constants/URLConstants";

// const apiDomain: string = window.location.origin !== "http://localhost:3001" ?
//   process.env.REACT_APP_API_DOMAIN || "http://localhost:3001" : "http://localhost:3001";

const apiDomain = 'http://localhost:3001'

export async function checkUser(email: string): Promise<string> {
    const METHOD_NAME = "checkUser :: ";
    try {
        let userAccountResponse: any = await axios.get(`${apiDomain}${CHECK_USER_EXIST_URL}?email=${email}`)
        console.log(METHOD_NAME + "checkUser userAccountResponse :: ", userAccountResponse);
        if (userAccountResponse.status ) {
            sessionStorage.setItem("stUserId", userAccountResponse.data.stUserId);
        }
        return `${userAccountResponse.status}`;

    } catch (err: any) {
        console.log(METHOD_NAME + "Error:: ", err.message);
        return INTERNAL_SERVER_ERROR;
    }
}