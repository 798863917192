'use client';

import Business3DYoung from './images/Business3DYoung.png';
import CircleLogo  from './CircleLogo';

import { Button,Card,Spinner } from 'flowbite-react';

interface ILoginSigUp {
    setSignUpStep(val: number): void;
}

export default function LoginSignUp(props: ILoginSigUp) {
    localStorage.clear();
    return (
        <>
            <div className="w-[25rem] h-[35rem] m-2 py-3 bg-white rounded-lg shadow border border-gray-300 flex flex-col justify-between items-center gap-5">
                <CircleLogo height={100} width={100}/>
                <div className="flex flex-col gap-y-1 mt-2 w-60 text-center text-gray-600">
                    <div className="leading-9 text-indigo-700 text-3xl font-bold">Circle App</div>
                    <div className="text-sm font-normal leading-6">Choose an option to
                        continue.</div>
                </div>
                <div className="flex flex-col items-center gap-y-4 my-6 w-60">
                    <Button onClick={() => props.setSignUpStep(1)} className="bg-indigo-700 enabled:hover:bg-indigo-800 focus:ring-indigo-800 w-full">
                        Continue with Email
                    </Button>
                    <div className=" text-black text-opacity-90 text-base font-medium leading-normal">OR</div>
                    <Button className="bg-white text-indigo-700 enabled:hover:bg-white border border-indigo-700 ring-0 w-full focus:ring-indigo-700">
                        Continue with SSO
                    </Button>
                </div>
                <img alt='users-cartoon-placeholder' width="188" height="246" className='-scale-x-100' src={Business3DYoung} />
            </div>
        </>
    )
}


