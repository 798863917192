export enum CAMERA_VIEW {
  USER = "user",
  ENVIRONMENT = "environment",
}

export enum INFO_SCREEN {
  CANCEL = "cancel",
  ERROR = "error",
  SUCCESS = "success",
  TOKEN_FAILURE = "token_failure",
}

export enum SELFIE_RULE_RESULT {
  PASS = "pass",
  FAIL = "fail",
  UNKNOWN = "unknown",
}

export const ratingResult = (result?: boolean) => {
  if (result === true) return SELFIE_RULE_RESULT.PASS;
  if (result === false) return SELFIE_RULE_RESULT.FAIL;
  return SELFIE_RULE_RESULT.UNKNOWN;
};

export const CANELLED_TEXT =
  "You have canceled the selfie action please continue again from the desktop app to restart taking selfie or click below";
export const ERROR_TEXT =
  "There was some error while taking the selfie please retry";
export const TOKEN_FAILURE_TEXT =
  "The link is expired, please close the 'Take Selfie' popup in desktop app and reopen it and then scan again";
export const SUCCESS_TITLE = "Selfie successfully uploaded!";
export const SUCCESS_CONTENT =
  "Please continue on the Desktop app to view your Circle Avatar.";
export const CAMERA_ERROR = "Some error in Camera loading, please try again.";
