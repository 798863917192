"use client";

import { useEffect, useState } from "react";
import LoginSignUp from "./LoginSignUp";
import LoginSignUpWithEmail from "./LoginSignUpWithEmail";
import LoginSignUpEmailOTPScreen from "./LoginSignUpEmailOTPScreen";
import LoginSignUpAddUserDetails from "./LoginSignUpAddUserDetails";
import LoginSignUpAddOrgDetails from "./LoginSignUpAddOrgDetails";
import { useSearchParams } from "react-router-dom";
import { checkUser } from "../../../Utilities/checkUser";
import { sendOTP } from "../../../Utilities/sendOTPMethod";
import { Spinner } from "flowbite-react";
import { REGEX_VALIDATION } from "../../../Constants/RegexConstants";
import AppDownload from "./AppDownload";

export default function LoginFlow() {
  const currentSignupStep = localStorage.getItem("signUpStep");
  const [signUpStep, setSignUpStep] = useState(
    currentSignupStep ? parseInt(currentSignupStep) : 0
  );
  const [userEmail, setUserEmail] = useState("");
  const [isExistingUser, setIsExistingUser] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const flow = searchParams.get("flow");
  const email = searchParams.get("email");

  useEffect(() => {
    if (flow && flow === "email") {
      if (email && email.match(REGEX_VALIDATION)) {
        setUserEmail(email);
        sendEmailOTP(email);
      } else setSignUpStep(1);
    }
  }, []);

  const sendEmailOTP = async (emailFromQueryParam?: string) => {
    try {
      // setIsExistingUser(
      //   (await checkUser(emailFromQueryParam || userEmail)) === "200"
      // );
      await sendOTP(emailFromQueryParam || userEmail);
      setSignUpStep(2);
    } catch (err: any) {
    }
  };

  return (
    <>
      <div className="w-auto h-screen bg-background flex relative justify-center items-center overflow-y-clip">
        {flow === "email" ? (
          <>
            {email && signUpStep === 0 && (
              <div className="absolute z-[1000] inset-1/2 flex flex-col items-center transform -translate-x-1/2 -translate-y-1/2">
                <Spinner
                  aria-label="spinner-from-web"
                  color="purple"
                  size="lg"
                />
              </div>
            )}

            {signUpStep === 0 && <LoginSignUp setSignUpStep={setSignUpStep} />}
            {signUpStep === 1 && (
              <LoginSignUpWithEmail
                setSignUpStep={setSignUpStep}
                setUserEmail={setUserEmail}
                userEmail={userEmail}
                setIsExistingUser={setIsExistingUser}
                isExistingUser={isExistingUser}
                sendEmailOTP={sendEmailOTP}
              />
            )}
            {signUpStep === 2 && (
              <LoginSignUpEmailOTPScreen
                userEmail={userEmail}
                setSignUpStep={setSignUpStep}
                setUserEmail={setUserEmail}
                isExistingUser={isExistingUser}
              />
            )}
            {signUpStep === 3 && (
              <LoginSignUpAddUserDetails setSignUpStep={setSignUpStep} />
            )}
            {signUpStep === 4 && (
              <LoginSignUpAddOrgDetails
                setSignUpStep={setSignUpStep}
                email={userEmail}
              />
            )}
          </>
        ) : (
          <AppDownload />
        )}
      </div>
    </>
  );
}
