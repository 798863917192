import axios from "axios";
export async function sendOTP(email: string) {
  try {
    const apiDomain: string =
      window.location.origin !== "http://localhost:3001"
        ? process.env.REACT_APP_API_DOMAIN || "http://localhost:3000"
        : "http://localhost:3000";

    console.log('apiDomain', apiDomain)
    console.log('window.location.origin', window.location.origin)
    const url = `${apiDomain}/api/v2/auth/passwordless/magicCodes`
    console.log('url', url)
    const response = await axios.post(
      url,
      {
        email: email
      }
    );

    console.log('send magic code response', response)
    localStorage.setItem(
      "passwordlessMagicCodeId",
      response.data.passwordlessMagicCodeId
    );
    return response;
  } catch (err) {
    console.log("Error while sending magic code", err);
    window.alert("Server error. Could not send magic code!");
  }
}
