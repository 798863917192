import axios from "axios";

export const getUserInvitations = async () => {
  try {
    const inviteToken = localStorage.getItem("inviteToken");
    const workspaceId = localStorage.getItem("workspaceId");

    const subdomain = window.location.host.split(".")[0];
    const apiDomain: string =
      window.location.origin !== `http://${subdomain}.localhost:3001`
        ? process.env.REACT_APP_API_DOMAIN || "http://localhost:3000"
        : "http://localhost:3000";

    const url = `${apiDomain}/api/v2/workspaces/${workspaceId}/admin/users`;
    console.log('URL: ' + url);
    const response = await axios.get(url, { headers: { Authorization: 'Bearer ' + inviteToken } });

  
    return response.data;
  } catch (err) {
    console.log("🚀 ~ Error while getting user invitations", err);

    if (axios.isAxiosError(err) && err.response) {
      const errorMessage = err.response.data?.message;
      console.log("Error", errorMessage)

      if (errorMessage === "Need to be an owner / admin to get users") {
        return { error: errorMessage };
      }
    }

    alert('Server error. Could not fetch data');
    return { error: 'Server error. Could not fetch data' };
  }
};
