import { FC } from "react";
import cancelledIcon from "./images/cancelled.svg";
import errorIcon from "./images/error.svg";
import successIcon from "./images/success-icon.svg";
import { Button } from "flowbite-react";
import {
  CANELLED_TEXT,
  ERROR_TEXT,
  INFO_SCREEN,
  SUCCESS_CONTENT,
  SUCCESS_TITLE,
  TOKEN_FAILURE_TEXT,
} from "./constants";

interface ErrorScreenProps {
  onShowInfoScreen: (screen: INFO_SCREEN | undefined) => void;
  infoScreen: INFO_SCREEN;
  error?: string;
}

const InfoScreen: FC<ErrorScreenProps> = ({
  onShowInfoScreen,
  infoScreen,
  error,
}) => {
  const isErrorScreen = infoScreen === INFO_SCREEN.ERROR;
  const isCancelScreen = infoScreen === INFO_SCREEN.CANCEL;
  const isSuccessScreen = infoScreen === INFO_SCREEN.SUCCESS;
  const isTokenFailureScreen = infoScreen === INFO_SCREEN.TOKEN_FAILURE;

  const getContent = () => {
    if (isCancelScreen) {
      return {
        text: CANELLED_TEXT,
        icon: cancelledIcon,
      };
    }
    if (isTokenFailureScreen) {
      return {
        text: TOKEN_FAILURE_TEXT,
        icon: errorIcon,
      };
    }
    if (isErrorScreen) {
      return {
        text: error ? `e: ${error}` : ERROR_TEXT,
        icon: errorIcon,
      };
    }
    if (isSuccessScreen) {
      return {
        text: SUCCESS_TITLE,
        icon: successIcon,
      };
    }
    return {
      text: CANELLED_TEXT,
      icon: cancelledIcon,
    };
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen px-8 text-center">
      <img src={getContent().icon} alt="cancel icon" />
      <div
        className={`mt-6 ${
          isSuccessScreen ? "text-green-600" : "text-red-500"
        }`}
      >
        {getContent()?.text}
      </div>
      <div className="mt-4 w-full">
        {isSuccessScreen ? (
          <div className="text-[16px] font-medium text-gray-600">
            {SUCCESS_CONTENT}
          </div>
        ) : (
          !isTokenFailureScreen && (
            <Button
              className="h-[34px] bg-indigo-700 enabled:hover:bg-indigo-800 w-full"
              onClick={() => onShowInfoScreen(undefined)}
            >
              Retry
            </Button>
          )
        )}
      </div>
    </div>
  );
};

export default InfoScreen;
