import { Button, Label, Radio } from "flowbite-react";
import { FC, useContext, useEffect, useState } from "react";
import { ROLE_NAMES } from "./utils/constants";
import { ZulipContext } from "../../../context/ZulipContext";
import RightDrawer from "../../Reusable/RightDrawer";
import { editUser } from "../../../Utilities/editUser";

interface AddEditRoleModalProps {
  open: boolean;
  toggleModal: () => void;
  selectedUser?: any;
  onRoleChangeSuccess: (response: any) => void;
}

const AddEditRoleModal: FC<AddEditRoleModalProps> = ({
  open,
  toggleModal,
  selectedUser,
  onRoleChangeSuccess,
}) => {
  const zulipContext = useContext(ZulipContext);
  const [role, setRole] = useState<string>(ROLE_NAMES.Member);
  const [initialRole, setInitialRole] = useState<string>(ROLE_NAMES.Member);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (selectedUser) {
      const userRole = selectedUser.role || ROLE_NAMES.Member;
      setRole(userRole);
      setInitialRole(userRole);
    }
  }, [selectedUser]);

  const updateUser = async (user: any, role: string) => {
    setIsProcessing(true);
    try {
      let response: any;
      response = await editUser(user, null, role);
      if (response.status === 200) {
        onRoleChangeSuccess(response);
      }
      toggleModal();
    } catch (error) {
      console.log("Edit Role error:", error);
      alert("Server error. Failed to edit user.");
    } finally {
      setIsProcessing(false);
      toggleModal();
    }
  };

  const onSubmitForm = async (e: any) => {
    e.preventDefault();
    if (selectedUser && role !== initialRole) {
      await updateUser(selectedUser, role);
    }
  };

  const onChangeRole = (e: any) => {
    setRole(e.target.value);
  };

  const isEditButtonDisabled = role === initialRole;

  return (
    <RightDrawer open={open} handleOpen={toggleModal}>
      <div className="">
        <h3 className="text-gray-500">Edit role for:</h3>
        {selectedUser && (
          <p className="text-xl font-semibold text-primary-500 py-1">
            {selectedUser.full_name}
          </p>
        )}
        <form onSubmit={onSubmitForm}>
          <div className="mt-5">
            <div className="mb-2 block">
              <Label htmlFor="email" value={selectedUser ? "" : "Invite as"} />
            </div>
            <fieldset
              className="flex max-w-md flex-col gap-4"
              onChange={onChangeRole}
            >
              <legend className="mb-4">Select a role for user</legend>
              {[ROLE_NAMES.Admin, ROLE_NAMES.Member].map((roleName) => (
                <div key={roleName} className="flex items-center gap-2">
                  <Radio
                    id={roleName.toLowerCase()}
                    name="userRole"
                    value={roleName}
                    checked={role === roleName}
                  />
                  <Label htmlFor={roleName.toLowerCase()}>{roleName}</Label>
                </div>
              ))}
            </fieldset>
          </div>
          <div className="w-full mt-10">
            <Button
              type="submit"
              className=" w-full bg-primary-500 hover:bg-primary-700 text-white"
              disabled={isEditButtonDisabled || isProcessing}
              isProcessing={isProcessing}
            >
              {`${selectedUser ? "Edit User" : "Send Invitations"}`}
            </Button>
          </div>
        </form>
      </div>
    </RightDrawer>
  );
};

export default AddEditRoleModal;
