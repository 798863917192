import {createBrowserRouter} from "react-router-dom";
import App from "../../App";
import LoginFlow from "../Content/LoginPage/LoginFlow";
import LoggedInUserPortal from "../Content/UserPortal/LoggedInUserPortal";
import VerifyMagicLink from "../Content/VerifyMagicLink/VerifyMagicLink";
import ClickSelfie from "../Content/ClickSelfie";
import CreateOrganization from "../Content/LoginPage/CreateOrganization";
import LoginSelectOrganization from "../Content/LoginPage/LoginSelectOrganization";
import AdminConsole from "../Content/AdminConsole";
import AcceptInvitation from "../Content/AdminConsole/AcceptInvitation";

export const ROUTER: any = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "signin",
    element: <LoginFlow />
  },
  {
    path: "portal",
    element: <LoggedInUserPortal />
  },
  {
    path: "createOrganization",
    element: <CreateOrganization />
  },
  {
    path: "selectOrganization",
    element: <LoginSelectOrganization />
  },
  {
    path: "verify",
    element: <VerifyMagicLink /> // Add a route for the magic link verification
  },
  {
    path: "click-selfie",
    element: <ClickSelfie />
  },
  {
    path: "admin/users",
    element: <AdminConsole />
  },
  {
    path: "admin/groups",
    element: <AdminConsole />
  },
  {
    path: "acceptInvitation",
    element: <AcceptInvitation />
  }
]);
