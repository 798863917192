import {Button} from "flowbite-react";
import {FC} from "react";
import usersImg from "./images/users.svg";

interface NoUsersScreenProps {
  onClickAddUser: () => void;
  isForUserGroup: any;
}

const NoUsersScreen: FC<NoUsersScreenProps> = ({onClickAddUser, isForUserGroup}) => {
  return (
    <div className="flex flex-col h-full items-center justify-between">
      <div />
      <div className="flex flex-col items-center">
        <div className="text-lg text-gray-500 mb-4 font-medium">
          No Users Added
        </div>
        <Button
          className="border border-indigo-700 bg-transparent text-indigo-700 h-[34px] w-[126px]"
          onClick={onClickAddUser}
        >
          {!isForUserGroup ? "Add User" : "Add User Group"}
        </Button>
      </div>
      <div>
        <img src={usersImg} alt="users" />
      </div>
    </div>
  );
};

export default NoUsersScreen;
